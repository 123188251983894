@use 'styles/variables' as *;

.message {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
  padding-bottom: 12px;
}

.button {
  margin-top: 36px;
}

.timerMessage {
  margin-top: 12px;
}
