@use 'styles/variables' as *;

.container {
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;

  > strong {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: inline;
    color: $primary100Base;
  }
}
