@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchWithMemberFilters {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.searchInput {
  max-width: 420px;
  margin-right: 24px;
}

.toggleSwitch {
  display: flex;
  align-items: center;
}

.toggleSwitchLabel {
  width: max-content;
  height: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
}

.activeToggleSwitchLabel {
  color: $headerTextColor;
}

.sortBySelect {
  display: flex;
  align-items: center;
}

.filtersButtons {
  display: flex;
  flex-direction: row;
}

@include for-mobile-only {
  .searchInput {
    width: 100%;
    max-width: unset;
    margin-right: 16px;
  }
}
