@use 'styles/break-points' as *;

.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 12px;
  }
}

.noItemsContainer {
  margin-top: 132px;
}

.noItemsTitle {
  margin: 36px 0 12px;
}

@include for-tablet-and-mobile-only {
  .container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
