@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.root {
  display: flex;
  justify-content: space-between;
  height: 44px;
  box-sizing: border-box;
  margin-left: auto;
  align-items: flex-end;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: $headerTextColor;
    padding: 12px 24px;
    width: 72px;
    height: 36px;
    border: 1px solid $borderAndSeparatorsColor;
    transition: all $transitionDuration ease-in-out;

    &:hover {
      border: 1px solid $secondary40;
      color: $secondary100Base;
    }

    &:first-child {
      border-top-left-radius: $borderRadiusDefault;
      border-bottom-left-radius: $borderRadiusDefault;
    }

    &:last-child {
      border-top-right-radius: $borderRadiusDefault;
      border-bottom-right-radius: $borderRadiusDefault;
    }

    &__selected {
      background-color: $electricBlue110;
      color: $contrastSurfacesTextColor;
      border: 1px solid $electricBlue110;

      &:hover {
        background-color: $electricBlue110;
        color: $contrastSurfacesTextColor;
        border: 1px solid $electricBlue110;
      }
    }
  }

  @include for-mobile-only {
    margin-top: 12px;
    width: 100%;

    &__button {
      width: 50%;
      height: 44px;
    }
  }
}
