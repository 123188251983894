@use './TextInput.module' as *;
@use 'styles/variables' as *;

.stubInputContainer {
  @extend .inputContainer;

  padding-top: 0;
}

.stubInput {
  @include inputBox;
  @include inputStyles;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 8px;
  background: $surfacesPrimary;
  width: 100%;
  color: $subtextColor;
  opacity: 1;

  &:hover {
    border: 1px solid $primary20;
  }

  &:focus {
    border: 1px solid $secondary40;
  }

  &.disabled {
    border: 1px solid $borderAndSeparatorsColor;
    cursor: default;
    background-color: $surfacesLight;
    color: $primary80;
  }
}

.beforeStubInputContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -29.5px;
}

.markerIconsContainer {
  display: flex;
  position: absolute;
  top: -26px;
  right: 0;
}

.inputIconContainer {
  display: flex;
  position: absolute;
  right: 16px;
  top: calc(50% - 4px);
}
