@use '../Variables.module.scss' as *;
@use 'styles/variables' as *;

.copyright {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $typo60;
}

.links {
  display: flex;
  gap: 24px;

  .link {
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $white100Base;
    cursor: pointer;
    text-decoration-line: none;
    text-align: center;
    font-style: normal;
    font-weight: 500;

    &:hover {
      color: $primaryText40;
    }
  }
}

@media (max-width: $mobileMaxWidth) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 60px;
    align-items: center;
    gap: 24px;
  }

  .links {
    flex-direction: column;
  }
}

@media (min-width: $mobileMaxWidth) {
  .container {
    display: flex;
    justify-content: space-between;
    padding: 36px 44px;
  }
}

@media (min-width: $desktopMinWidth) {
  .container {
    display: flex;
    justify-content: space-between;
    padding: 36px 156px;
  }
}
