$primary120: #060d19;
$primaryDark: #131825;
$primary100Base: #0f2342;
$primary80: #3f4f68;
$primary60: #6f7b8e;
$primary40: #9fa7b3;
$primary30: #CFD3D9;
$primary20: #cfd3d9;
$primary10: #e7e9ec;
$primary6: #f1f2f4;
$primary2: #fafbfb;

$secondary120: #077ddf;
$secondary100Base: #27a8e5;
$secondary40: #a5d5fc;
$secondary30: #A5A8AF;
$secondary10: #ecf9ff;
$secondary6: #f2f9ff;
$secondary3: #f8fcff;

$red100Base: #ec4d3d;
$red120: #b14848;
$red50: #fbb2b2;
$red10: #fef0f0;

$orange120: #ff4909;
$orange100Base: #ff6b37;
$orange50: #ffb59b;
$orange10: #fff0eb;

$green110: #00c4ac;
$green100Base: #53C530;
$green50: #80e1d6;
$green20: #C0EDB2;
$green10: #EFFAEC;

$lightOrange100Base: #ff8a00;
$lightOrange50: #ffc480;
$yellow: #F7CC4E;
$lightOrange10: #fff3e5;
$yellow110: #ffc312;
$yellow20: #FDF5DC;

$darkBlue120: #042d57;
$darkBlue110: #053361;
$darkBlue100Base: #053668;
$darkBlue90: #1e4a77;
$darkBlue20: #cdd7e1;
$electricBlue110: #0A51EB;
$electricBlue100: #1960F6;
$electricBlue80: #4F84F8;
$electricBlue70: #76A0F9;
$electricBlue20: #D8E4FD;
$electricBlue10: #EBF1FE;
$lightBlue100Base: #45C3FF;

$white100Base: #ffffff;

$violet100Base: #7868FF;
$violet120Base: #6053cc;

$black100Base: #000000;
$dark100: #0c111d;
$dark70: #57657B;

$grey: #777c91;

$surfacesPrimary: $white100Base;
$surfacesSidebar: $primaryDark;
$surfacesOverlaySolid: #9295a2;
$surfacesOverlay: rgba($surfacesOverlaySolid, 0.4);
$surfacesLight: #f1f2f4;

$fontFamilyMonsterrat: 'Montserrat', 'sans-serif';
$fontFamilySourceCodePro: 'Source Code Pro';
$fontFamilyMonaco: 'Monaco', 'monospace', 'courier';

$h1FontSize: 48px;
$h2FontSize: 36px;
$h3FontSize: 28px;
$h4FontSize: 22px;
$h5FontSize: 18px;
$h6FontSize: 16px;
$h1LineHeight: 56px;
$h2LineHeight: 44px;
$h3LineHeight: 36px;
$h4LineHeight: 28px;
$h5LineHeight: 24px;
$h6LineHeight: 22px;

$pFontSize: 14px;
$pLineHeight: 20px;
$smallFontSize: 12px;
$smallLineHeight: 16px;
$tableHeadFontSize: 9px;
$tableHeadLineHeight: 12px;
$tagFontSize: 9px;
$tagLineHeight: 10px;
$menuHeaderFontSize: 10px;
$menuHeaderLineHeight: 12px;

$defaultLetterSpacing: 0.1em;

$seminormalFontWeight: 400;
$normalFontWeight: 500;
$semiboldFontWeight: 600;
$boldFontWeight: 700;

$headerTextColor: $primary100Base;
$defaultTextColor: $primary80;
$subtextColor: $primary40;
$captionColor: $primary20;
$lightCaptionColor: $primary10;
$placeholderTextColor: $primary40;

$contrastSurfacesTextColor: $white100Base;
$contrastSurfacesIconColor: $white100Base;
$contrastSurfacesAciveTextColor: $yellow;
$contrastSurfacesActiveIconColor: $yellow;

$defaultErrorColor: $red100Base;
$defaultLinkColor: $electricBlue100;
$defaultLinkHoverColor: $electricBlue80;

$borderAndSeparatorsColor: $primary10;

$inputHoverBorderColor: $primary40;

$borderRadiusDefault: 4px;
$bottomPaddingDefault: 60px;

$tableBodyCellVerticalPadding: 14px;
$tableBodyCellHorizontalPadding: 10px;

$tableHeadStickyZIndex: 2;

$transitionDuration: 0.25s;
