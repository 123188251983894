.dashboardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 44px;
  margin-bottom: 24px;
}

.filtersButtons {
  flex-direction: row-reverse;
  gap: 24px;
}
