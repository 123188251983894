@use 'styles/break-points' as *;

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

@include for-tablet-and-mobile-only {
  .headerContainer {
    margin-bottom: 36px;
  }

  .titleContainer {
    display: none;
  }

  .button {
    width: 100%;
  }

  .searchBar {
    width: 100%;
  }
}
