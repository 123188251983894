@use 'styles/variables' as *;

.loginFormContent {
  display: flex;
  flex-direction: column;

  .emailInput {
    padding-top: 0;
  }
}

.formTitleContainerOverForm {
  height: auto;
  width: 100%;
  margin-bottom: 12px;
}

.formTitleFullWidth {
  width: 255px;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  font-weight: $boldFontWeight;
  font-family: $fontFamilyMonsterrat;
  color: $headerTextColor;
  white-space: pre-wrap;
  width: 97%;
}

.submitButton {
  margin-top: 36px;
}
