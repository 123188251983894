@use 'styles/variables' as *;
@use 'components/Tooltip/Common.module' as *;

.avatarContainer {
  position: relative;
  display: flex;
  justify-content: center;

  &:hover .tooltip {
    display: flex;
  }
}

.avatar {
  width: 2.8em;
  height: 2.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(14, 176, 206);
  line-height: 2.8em;
}

.tinyAvatar {
  width: 23.8px;
  height: 23.8px;
  font-size: 8.5px;
  line-height: 23.8px;
}

.smallAvatar {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 10px;
}

.mediumAvatar {
  width: 36px;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
}

.tooltip {
  $offset: 2px;

  > div {
    &:global(.MuiTooltip-tooltipPlacementTop) {
      margin-top: $defaultTopMargin + $offset;
    }

    &:global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: $defaultBottomMargin - $offset;
    }
  }
}
