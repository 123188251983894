@use 'styles/variables' as *;

$stickyContainerZIndex: 3;

.stickyContainer {
  position: sticky;
  background-color: white;
  z-index: $stickyContainerZIndex;
  box-shadow: 0 -8px 8px $surfacesPrimary;

  &.stuck {
    box-shadow: 0 16px 24px -8px rgba(15, 35, 66, 0.2);
  }
}
