@use 'styles/variables' as *;

.name {
  &:hover {
    overflow-wrap: anywhere;
    white-space: break-spaces;
  }
}

.error {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $red100Base;
}

.nameCellContainer {
  padding-left: 8px;
  overflow: hidden;
}

.fileIcon {
  flex-basis: 24px;
  flex-shrink: 0;
  flex-grow: 0;
}

.fileIconContainer {
  padding-right: 0;
}
