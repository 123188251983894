@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.card {
  background-color: $white100Base;
  padding-top: 28px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: 1px solid $electricBlue10;
}

.head {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &:not(.noMargin) {
    margin-bottom: 20px;
  }
}

.headContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.title {
  color: $primary100Base;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.secondaryTitle {
  margin-left: auto;
}

.tag {
  border-radius: 2px;
  background: $electricBlue10;
  color: $electricBlue100;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  flex-shrink: 0;
  margin-top: 7px;
}

@include for-mobile-only {
  .useMobileLayout {
    .headContainer {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
