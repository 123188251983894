@use 'styles/variables' as *;

.h {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #0F2342;
}

.h1 {
  @extend .h;

  font-size: $h1FontSize;
  line-height: $h1LineHeight;
}

.h2 {
  @extend .h;

  font-size: $h2FontSize;
  line-height: $h2LineHeight;
}

.h3 {
  @extend .h;

  font-size: $h3FontSize;
  line-height: $h3LineHeight;
}

.h4 {
  @extend .h;

  font-size: $h4FontSize;
  line-height: $h4LineHeight;
}

.h5 {
  @extend .h;

  font-size: $h5FontSize;
  line-height: $h5LineHeight;
}

.h6 {
  @extend .h;

  font-size: $h6FontSize;
  line-height: $h6LineHeight;
}
