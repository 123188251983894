@use "styles/variables" as *;
@use "./LeftNavigationHeader/LeftNavigationHeader.module" as *;
@use "../../styles/break-points.scss" as *;

$leftNavigationWidthExpanded: 280px;
$leftNavigationWidthCollapsed: 72px;

$toggleButtonWidth: 16px;
$toggleButtonHeight: 60px;

@include for-desktop-only {
  .navBarContainerExpanded {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background-color: $surfacesSidebar;
    width: $leftNavigationWidthExpanded;
    transition: width 0.5s ease-in-out;
    position: relative;
    z-index: 9999;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: -$toggleButtonWidth;
      width: $toggleButtonWidth;
      min-height: 100%;
    }

    &:hover {
      .toggleButton {
        opacity: 1;
      }
    }
  }

  .navBarContainerCollapsed {
    @extend .navBarContainerExpanded;
    width: $leftNavigationWidthCollapsed;
  }

  .toggleImg {
    width: 6px;
    height: 10px;
    transition: all 0.25s ease-in-out;

    path {
      stroke: $dark100;
    }
  }

  .toggleImgOpened {
    @extend .toggleImg;
    transform: rotate(180deg);
  }

  .toggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -$toggleButtonWidth;
    top: calc(100% / 2 - #{$toggleButtonHeight * 0.5});
    background-color: $yellow;
    width: $toggleButtonWidth;
    height: $toggleButtonHeight;
    border-radius: 0 8px 8px 0;
    transition: all 0.25s ease-in-out;
    opacity: 0;
    cursor: pointer;
    &:hover .imageTooltip {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include for-tablet-and-mobile-only {
      display: none;
    }
  }

  .imageTooltip {
    max-width: 97px;
    min-width: 91px;
    max-height: 32px;
    height: 32px;
    background: $surfacesPrimary;
    border-radius: 4px;
    position: absolute;
    left: 24px;
    top: 14px;
    display: none;
    border: 1px solid $primary120;
  }

  .tooltipText {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $primary100Base;
  }

  .userCard {
    @include for-tablet-and-mobile-only {
      display: none;
    }
  }
}

@include for-tablet-and-mobile-only {
  .isOpenedOnMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: $dark100;
    width: $leftNavigationWidthExpanded;
    transition: width 0.5s ease-in-out;
    position: relative;
    z-index: 9999;
  }

  .toggleButton {
    display: none;
  }

  .userCard {
    display: none;
  }

  .navBarContainer:not(.isOpenedOnMobile) {
    display: none;
  }
}
