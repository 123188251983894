@use '../../../Auth/Variables.module.scss' as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $typo60;
  margin-top: 12px;
}

.image {
  width: 100px;
  height: 100px;
}
