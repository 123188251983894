@use "styles/variables" as *;

.container {
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 12px;
  border-radius: 6px;
  display: flex;
}

.info {
  background-color: $electricBlue10;
}

.warning {
  background-color: $yellow20;
}

.icon {
  margin-right: 4px;
}

.text {
  font-size: $pFontSize;
  font-weight: $normalFontWeight;
  line-height: $pLineHeight;
  color: $primary80;
}
