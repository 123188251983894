@use 'styles/break-points' as *;

.filterInput {
  width: 330px;
  margin: 0;

  .filterInputSelectControl {
    padding: 7px 0;
    height: auto;
  }
}


@include for-mobile-only {
  .subtitleTags {
    display: none;
  }
}
