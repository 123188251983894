@use 'styles/variables' as *;

.list {
  display: flex;
}

.listItem {
  &:not(:first-child) {
    margin-left: -10px;
  }
}

.userAvatar {
  background-color: $white100Base;
  border-radius: 50%;
  padding: 2px;
}

.skeletonCircle {
  border: 1px solid $white100Base;
}
