@use 'styles/variables' as *;

.navButton {
  border: 0;
  background-color: transparent;
  padding: 0;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultLinkColor;
  outline: 0;
  cursor: pointer;

  &:hover {
    color: $defaultLinkHoverColor;
  }

  &:disabled {
    color: $primary40;
    cursor: default;
  }
}
