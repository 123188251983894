@use 'styles/variables' as *;

$width: 600;
$height: 400;

.container {
  width: 100%;
  height: #{$height}px;
}

.buttonsContainer {
  margin-top: 24px;
  display: flex;
}

.deleteButton {
  margin-right: 0;
  margin-left: auto;
}

.cancelButton {
  margin-left: 12px;
}

.deleteButton, .cancelButton, .submitButton {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnerContainer {
  width: #{$width}px;
  height: #{$height}px;
  border: 1px solid $primary6;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    left: auto !important;
  }
}

.reactCropContainer {
  width: #{$width}px;
  height: #{$height}px;
}

:export {
  width: $width;
  height: $height;
  white: $white100Base;
  gray: $primary10;
}
