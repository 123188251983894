@use 'styles/variables' as *;

.noContentContainer {
  display: flex;
  flex-direction: row;
}

.noContentText {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
}

.warningIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
