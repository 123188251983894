@use 'styles/variables' as *;
@use '../Variables.module.scss' as *;

.nameInput {
  padding-top: 0;
}

.fullNameContainer {
  display: flex;
  gap: 24px;

  @media (max-width: $mobileMaxWidth) {
    flex-direction: column;
  }
}

.footer {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $white100Base;
  margin-top: 24px;

  > div {
    display: inline-block;
  }
}

.submit {
  margin-top: 36px;
}
