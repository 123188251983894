@use 'styles/variables' as *;

.inputContainer {
  display: flex;
  align-items: flex-end;
  margin: 24px 0 36px 0;

  .codeInputContainer {
    padding-top: 0;
  }

  .countryCodeSelectorContainer {
    width: 32%;
  }

  .phoneNumberInputContainer {
    width: 68%;
    padding-top: 0;
  }

  .countryCodeSelectorContainer,
  .phoneNumberInputContainer {
    margin-top: 0;
    position: relative;

    > p {
      position: absolute;
      bottom: -25px;
    }
  }
}

.message {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}
