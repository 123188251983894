@use 'styles/variables' as *;
@use 'styles/break-points' as *;

$rightCardWidth: 330px;
$dashboardMinHeight: 784px;

.dashboard {
  padding: 36px 55px;
  flex: 1 1;
  max-width: 100%;
}

.pageTitle {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary100Base;
  }
}

.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 100%;
  flex: 1 1;
}

.timeRangeInput {
  margin-left: auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: $primary100Base;
}

.leftColumn, .rightColumn {
  display: flex;
  gap: 24px;
  flex-direction: column;
  flex: 1;
}

.card {
  background-color: $white100Base;
  flex: 1;
}

.leftColumn {
  width: calc(100% - 360px);
  max-width: calc(100% - 360px);
  margin-right: 30px;

  .card:first-child {
    flex: 1 1 auto;
  }
}

.rightColumn {
  min-width: $rightCardWidth;
  max-width: $rightCardWidth;
}

.runExtractionForm {
  flex: 1;
}

@include for-tablet-and-mobile-only {
  .runExtractionForm {
    min-height: 558px;
  }

  .dashboard {
    padding: 0 16px 24px 16px;
    min-height: auto;
    height: auto;
  }

  .mainContent {
    flex-direction: column;
    gap: 24px;
  }

  .rightColumn {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }

  .leftColumn {
    width: 100%;
    max-width: 100%;
  }

  .card {
    height: 332px;
    flex: auto;
  }
}
