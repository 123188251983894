@use 'styles/variables' as *;

.detailsForm {
  position: relative;
}

.detailsFormContent {
  display: grid;
  grid-column-gap: 30px;
}

.detailsFormHeader {
  position: relative;
  width: fit-content;
  margin-bottom: 24px;
}

.editModeDetailsFormHeader {
  margin-bottom: 0;
}

.field {
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.alignedStartField {
  align-items: flex-start;
}

.withTopBorderField {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.overflowed {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    overflow: auto;
    white-space: normal;
    text-overflow: clip;
    word-break: break-all;
  }
}

.fieldLabel {
  @extend .overflowed;

  padding: 14px 10px;
  color: $primary40;
}

.fieldValue {
  @extend .overflowed;

  position: relative;
  padding: 14px 10px;
  color: $primary80;
}

.editInformationButton {
  position: absolute;
  top: 50%;
  left: calc(100% + 12px);
  width: max-content;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: $normalFontWeight;
  line-height: 20px;
  padding: 8px;
  border-radius: 4px;
}

.closeEditorButton {
  width: 140px;
  margin-right: 12px;
}

.saveChangesButton {
  width: fit-content;
}

.editFormActions {
  display: flex;
  position: absolute;
  top: -8px;
  right: 0;
}

.toggleHiddenFieldsButtonField {
  @extend .field;

  color: $secondary100Base;
  padding: 14px 10px;
}

.toggleHiddenFieldButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & > svg {
    transition: transform 0.25s ease-in-out;
    margin-left: 10px;
  }
}

.toggledHiddenFieldButton {
  & > svg {
    transform: rotate(180deg);
  }
}

.noFieldsContent {
  @extend .field;

  padding: 14px 10px;
  color: $primary40;
  border-top: 1px solid $borderAndSeparatorsColor;
}
