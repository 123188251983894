@use 'styles/variables' as *;

.label {
  display: block;
  width: fit-content;
  position: relative;
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
  overflow-wrap: anywhere;
}

.requiredSymbol {
  color: $red100Base;
  padding-left: 3px;
}

.questionIcon {
  position: absolute;
  right: -24px;
  top: 0;
}
