@use 'styles/variables' as *;

.scrollableTabContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.scrollableTabContainerWithWhiteShadow {
  &:before {
    position: absolute;
    width: 24px;
    height: 100%;
    content: '';
    transform: rotate(180deg);
    background: linear-gradient(270deg, $white100Base 0%, rgba(255, 255, 255, 0) 100%);
  }
}

.overflowContainer {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;

  > div {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
}

.chevron {
  cursor: pointer;
}

.disabledChevron {
  cursor: default;

  & > path {
    stroke: $primary20;
  }
}

.chevronLeft {
  @extend .chevron;

  transform: rotate(270deg);
}

.chevronRight {
  @extend .chevron;

  transform: rotate(90deg);
}

.scrollChevronsContainer {
  display: flex;
  flex-direction: row;
}

.scrollElements {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: $white100Base;
}

.scrollElementsWithWhiteShadow {
  &::after {
    position: absolute;
    width: 24px;
    left: -24px;
    height: 100%;
    content: '';
    background: linear-gradient(270deg, $white100Base 0%, rgba(255, 255, 255, 0) 100%);
  }
}
