@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.name {
  color: $subtextColor
}

.nameCell {
  padding-left: 0;
}

@include for-tablet-and-mobile-only {
  .mobilePadding {
    padding: 12px 10px 12px 0;
  }
}
