@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: $primary100Base;
  margin-bottom: 36px;
}

.block {
  margin-bottom: 48px;
}

@include for-tablet-and-mobile-only {
  .header {
    display: none;
  }

  .block {
    margin-bottom: 28px;
  }
}
