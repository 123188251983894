@use 'components/Button/Button.module' as *;
@use 'styles/variables' as *;
@use 'components/Button/ButtonVariables' as *;

.label {
  @extend .button;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}

.disabledLabel {
  color: $warningBtnTextColorDisabled;
  background-color: $warningBtnBackgroundColorDisabled;
  box-sizing: $warningBtnBoxSizing;
  border: $warningBtnBorderDisabled;
  cursor: default;

  &:hover {
    @extend .disabledLabel;
  }
}

.loader {
  margin-top: -9px;
}
