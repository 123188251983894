@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  display: flex;
  flex-direction: column;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.readViewContainer {
  display: flex;
}

.detailsContainer {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nameRow {
  display: flex;
  align-items: center;
  height: 36px;
}

.username {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $primary100Base;
  margin-right: 12px;
  max-width: 450px;
}

.role {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.editLink {
  display: flex;
  align-items: center;
  padding: 8px;

  &:hover {
    color: $defaultLinkColor;
    background-color: $secondary10;
    border-radius: 6px;
  }
}

.editLinkIcon {
  margin-right: 4px;
}

.avatarContainer {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .hoverContent {
    $size: 48px;

    position: absolute;
    top: calc(50% - ($size / 2));
    left: calc(50% - ($size / 2));
    background: $white100Base;
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.controls {
  display: flex;
  margin-left: auto;
  margin-right: 0;
}

.closeButton {
  margin-right: 12px;
}

.editViewContainer {
  display: flex;
  flex-direction: space-between;
  margin-top: 12px;
}

.buttonsMobile {
  display: none;
}

@include for-tablet-and-mobile-only {
  .avatar > div {
    max-width: 36px;
    max-height: 36px;

    p {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .detailsContainer {
    margin-left: 16px;
  }

  .username {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 0;
  }

  .nameRow {
    gap: 4px;
  }

  .role {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .editLinkText {
    display: none;
  }

  .editViewContainer {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .avatarContainer {
    position: relative;

    .hoverContent {
      $size: 36px;

      top: 0;
      left: 0;
      height: $size;
      width: $size;
    }
  }

  .buttonsDesktop {
    display: none;
  }

  .buttonsMobile {
    margin-left: 0;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    gap: 12px;
    margin-bottom: 12px;
  }

  .closeButton {
    margin-right: 0;
  }
}
