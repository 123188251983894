@use 'styles/variables' as *;

.divider {
  margin-top: 24px;
}

.addIpButton {
  position: absolute;
  z-index: 4;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-right: 8px;
  background-color: $white100Base;
  font-feature-settings: 'tnum' on, 'lnum' on;

  &:hover {
    color: $secondary120;

    > svg {
      stroke: $secondary120;
    }
  }

  > svg {
    stroke: $secondary100Base;
  }
}

.removeIpButton {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  color: $red100Base;

  &:hover {
    color: $secondary120;
  }
}

.submitButton {
  margin-top: 36px;
}
