@use 'styles/variables' as *;
@use '../../Variables.module.scss' as *;

$disabledBackground: #767D8F;

@mixin authButton {
  width: 100%;
  height: 52px;
  background: linear-gradient(90.2deg, $lightYellow 0.19%, $lightOrange 100.55%);
  border: 1px solid $lightYellow;
  box-shadow: 0 4px 16px rgba(255, 195, 18, 0.4);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: $white100Base;
  transition: --shadowColor 0.55s, --endColor 0.75s;

  &:not(:disabled) {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.32);
  }

  &:hover, &:focus:not(:disabled) {
    color: $white100Base;
    cursor: pointer;
  }

  &:disabled {
    background: $disabledBackground;
    color: $typo60;
    box-shadow: none;
    border: none;
    cursor: default;
  }

  &.loading, &:not(:disabled):hover {
    background: linear-gradient(90.2deg, $lightYellow 0.19%, $lightPink 100.55%);
    box-shadow: 0 4px 16px rgba(255, 114, 173, 0.32);
  }
}

.button {
  @include authButton;
}
