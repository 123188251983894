@use 'styles/variables' as *;
@use 'styles/break-points' as *;

$rightSidePopupWidth: 390px;
$rightSidePopupMobileWidth: 100vw;

.rightSidePopup {
  display: flex;
  position: fixed;
  top: 0;
  right: -$rightSidePopupWidth;
  width: $rightSidePopupWidth;
  height: 100vh;
  background-color: $white100Base;
  box-shadow: 0px 0px 60px rgba(15, 35, 66, 0.2);
  padding: 36px;
  z-index: 100;
  overflow-x: hidden;
  transition: transform 0.3s ease-in-out;
}

.rightSidePopupOpenWithTranslate {
  transform: translateX(-$rightSidePopupWidth);
}

.rightSidePopupOpen {
  right: 0;
}

.overlay {
  overflow-y: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
}

@include for-mobile-only {
  .rightSidePopup {
    width: $rightSidePopupMobileWidth;
    right: -$rightSidePopupMobileWidth;
  }

  .rightSidePopupOpenWithTranslate {
    transform: translateX(-$rightSidePopupMobileWidth);
  }
}
