@use 'styles/variables' as *;

.restUsersAvatar {
  > div {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $white100Base;
    text-transform: uppercase;
    background: $primary40;
    transition: 0.25s background-color ease-in-out;

    &:hover {
      background-color: $secondary100Base;
    }
  }
}

.hasItemsSelected {
  > div {
    background-color: $secondary100Base;
  }
}

.activeAvatar {
  > div {
    background-color: $secondary100Base;
  }

  box-shadow: 0 0 0 2px $secondary100Base;
}
