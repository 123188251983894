@use 'styles/variables' as *;

.field {
  display: flex;
  flex-direction: row;
  height: fit-content;
  border-top: 1px solid $borderAndSeparatorsColor;

  & > div {
    padding: 0 10px;
  }

  .fieldValue {
    padding-right: 20px;
  }
}

.lastFieldInColumn {
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.fieldsContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 30px;
  height: 100%;
}
