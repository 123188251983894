.notificationList {
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 72px;
  right: 0;
  position: fixed;
  z-index: 100000;
  transition: left 0.5s ease-in-out;
  padding: 0 16px 16px 16px;

  & > * {
    margin-top: 8px;
  }
}
