@use 'styles/variables' as *;

.applyFilterButton {
  width: 100%;
  margin-top: 36px;
}

.filterLayoutTitle {
  margin-bottom: 16px;
}

.permissionGroupSelect {
  margin-top: 0;
}
