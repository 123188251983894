@use 'styles/variables' as *;
@use 'styles/mixins' as *;
@use 'styles/break-points' as *;

$actionsMinWidth: 44px;

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: $actionsMinWidth;
}

.rowActionsContainer:hover {
  & > .actionsButton,
  & > * > .actionsButton,
  & > * > * > .actionsButton,
  & > * > * > * > .actionsButton {
    opacity: 1;
  }
}

.actionsButton {
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  transition: 0.25s background-color ease-in-out;
  outline: 0;
  cursor: pointer;
  opacity: 0;
  border-radius: $borderRadiusDefault;
  height: 24px;
  width: 24px;

  &:hover {
    background-color: $secondary10;
  }

  & > svg {
    & > circle {
      transition: 0.25s fill ease-in-out;
    }
  }

  &.alwaysVisible {
    opacity: 1;
  }
  
  @include for-tablet-and-mobile-only {
    opacity: 1;
  }

}

.actionItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.disabledAction {
  pointer-events: none;
}

.spinner {
  position: absolute;
}

.loading {
  justify-content: flex-start;
  align-items: flex-start;
  cursor: default;
}

:export {
  actionsMinWidth: $actionsMinWidth;
}
