@use 'styles/variables' as *;

.tableBodyCell {
  display: flex;
  align-items: center;
  padding: $tableBodyCellVerticalPadding $tableBodyCellHorizontalPadding;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableBodyCellHover {
  @extend .tableBodyCell;

  &:hover {
    overflow: auto;
    white-space: normal;
    text-overflow: clip;
  }
}

.noPaddings {
  @extend .tableBodyCell;
  padding-top: 0;
  padding-bottom: 0;
}

.lastColumnCell {
  @extend .tableBodyCell;
  justify-content: flex-end;
}

.nameCell {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.errorCell {
  @extend .nameCell;

  color: $red100Base;
}

.withRightPadding {
  padding-right: 10px;
}

.dashedTopBorder {
  border-top: 1px dashed $primary10;
}

.withSeparator {
  border-right: 1px solid $borderAndSeparatorsColor;
}
