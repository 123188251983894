@use 'styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.jsonView {
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: $white100Base;
  }
}

.processingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .processingValueContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .processingTitle, .processingText {
      font-size: $pFontSize;
      line-height: $pLineHeight;
      color: $primary100Base;
    }
  
    .processingTitle {
      font-weight: $boldFontWeight;
      margin-bottom: 16px;
    }
  
    .processingText {
      text-align: center;
    }
  }
}

.linkButton {
  margin-right: 4px;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}
