@use 'styles/variables' as *;

.spinner {
  position: absolute;
}

:export {
  fontFamily: $fontFamilyMonsterrat;
  labelColor: $primary100Base;
  iconColor: $primary60;
  checkboxBackgroundColor: $surfacesLight;
  iconBorderColor: $borderAndSeparatorsColor;
}
