@use 'styles/variables' as *;

.datePickersInner {
  display: flex;
}

.datePickersLabel {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $primary100Base;
  margin-bottom: 8px;
  padding-top: 24px;
}

.datePickerFrom, .datePickerTo {
  flex: 1;
}

.datePickerFrom input {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.datePickerTo input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
