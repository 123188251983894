@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.contextualViewContainer {
  position: relative;
  width: 980px;
  padding: 36px 55px 60px 55px;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

.closeImage {
  position: absolute;
  top: 36px;
  right: 36px;

  width: 36px;
  height: 36px;

  cursor: pointer;

  stroke: $primary80;
}

.minimizedContextualViewContainer {
  width: 710px;
}


@include for-tablet-and-mobile-only {
  .contextualViewContainer {
    width: 100vw;

    padding: 28px 16px;
  }
}

@include for-mobile-only {
  .closeImage {
    top: 32px;
  }
}
