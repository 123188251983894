@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.userCardContainer {
  margin-top: auto;
  display: flex;
  background-color: inherit;
  height: 60px;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
  padding: 12px 18px;

  &:hover {
    background-color: $primaryDark;
  }

  @include for-tablet-and-mobile-only {
    padding: 0;
    max-height: 36px;
    max-width: 36px;

    &:hover {
      background-color: transparent;
    }
  }
}

.activeUserCardContainer {
  @extend .userCardContainer;
  background-color: inherit;
}

.imgContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.activeCircle {
  width: 36px;
  height: 36px;
  position: absolute;
  border-radius: 100%;
}

.userImg {
  height: 32px;
  width: 32px;
  border-radius: 100%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 208px;
  overflow: ellipsis;
  margin-left: 18px;
  font-family: $fontFamilyMonsterrat;
  color: $contrastSurfacesTextColor;
  font-weight: $boldFontWeight;

  @include for-tablet-and-mobile-only {
    display: none;
  }
}

.userName {
  max-width: 150px;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
}

.activeUserName {
  @extend .userName;
  color: $white100Base;
}

.companyName {
  max-width: 150px;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  color: $primary30;
}

.dotsIcon {
  min-width: 24px;
  min-height: 24px;

  @include for-tablet-and-mobile-only {
    display: none;
  }
}
