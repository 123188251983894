@use "styles/variables" as *;

.buttonWithImage {
  padding: 0;
  color: $electricBlue100;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;

  svg {
    stroke: $electricBlue100;
  }

  &:hover {
    color: $secondary100Base;

    svg {
      stroke: $secondary100Base;
    }
  }
}
