@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  position: relative;
}

.title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: $primary100Base;
  display: flex;
  align-items: center;
  gap: 8px;
}

.body {
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: $primary100Base;
  margin-top: 24px;
}

.secondaryMessage {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $primary80;
  margin-top: 8px;
}

.analyzeDocument {
  margin-top: 28px;

  &.topRight {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}

.switchContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 24px;

  .switch {
    margin-right: 12px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.switchLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary100Base;
  cursor: pointer;
}

@include for-tablet-and-mobile-only {
  .title {
    font-size: $h6FontSize;
    line-height: $h5LineHeight;
  }

  .switchContainer {
    margin-top: 14px;
  }

  .body {
    margin-top: 28px;
  }

  .message {
    line-height: $pLineHeight;
    text-align: center;
  }

  .analyzeDocument {
    margin-top: 24px;

    &.topRight {
      position: static;
      margin-top: 24px;
    }
  }

  .autoDetectionEmptyIcon {
    margin-top: 32px;
  }
}
