@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  position: relative;
  padding: 28px;
  background-color: $dark100;
  border-radius: 4px;
  font-family: 'Monaco', monospace;
  font-weight: $seminormalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.value {
  color: $electricBlue100;
  margin-left: 5px;
}

.label {
  color: $yellow110;
}

.punctuation {
  color: $white100Base;
}

.childStyle {
  margin-left: 20px;
}

.pointer {
  cursor: pointer;
}

.loadingText {
  color: $primary40;
}

.copyButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: $dark100;
  color: $white100Base;
  border-color: $dark70; 
}

.copyIcon {
  > rect {
    stroke: $white100Base;
  }

  > path {
    fill: $white100Base;
  }
}

@include for-tablet-and-mobile-only {
  .container {
    padding: 12px;
  }

  .childStyle {
    margin-left: 10px;
  }

  .copyButton {
    position: relative;
    top:auto;
    right:auto;
    margin-bottom: 10px;
  }
}
