@use 'styles/variables' as *;
@use '../../Auth/Variables.module.scss' as *;

.container {
  display: block;
  cursor: pointer;
}

.disabled {
  @extend .container;
  cursor: auto;
}

.tooltip {
  transform: translate(-50%, calc(-100% - 10px));
}

:export {
  chartLineColor: $secondary100Base;
  tooltipBarColor: $electricBlue100Base;
}
