@use 'components/LeftNav/LeftNavigation.module.scss' as *;

.pageWrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;

  margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
