@use 'styles/variables' as *;
@use 'styles/break-points' as *;

$leftNavigationHeaderHeight: 72px;

@include for-desktop-only {
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-height: 100%;
    border-bottom: 1px solid $grey;
    background-color: $dark100;
    padding: 22px 0 22px 24px;
  }

  .innerContainer {
    transition: width 0.5s ease-in-out;
    overflow: hidden;
  }

  .mainLogoContainerExpanded .innerContainer {
    width: 100%;
  }

  .mainLogoContainerCollapsed .innerContainer {
    @extend .mainLogoContainerExpanded;
    width: 30px;
  }

  .mainLogo {
    min-height: 26px;
    min-width: 148px;
    max-width: 148px;
    cursor: pointer;
  }
}

@include for-tablet-and-mobile-only {
  .headerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    max-height: 100%;
    border-bottom: 1px solid $grey;
    background-color: $dark100;
    padding: 52px 0 20px 24px;
  }
}
