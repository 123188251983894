@use 'styles/variables' as *;
@use 'styles/break-points' as *;

$borderSize: 1px;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 36px;
  border: $borderSize solid $borderAndSeparatorsColor;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: $secondary6;
    border: 1px solid $secondary40;
  }

  .content {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  .documentTypeNameContainer {
    display: inline;
    overflow-wrap: break-word;
  }

  .documentTypeName {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    margin-right: 10px;
  }

  .tag {
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
  }

  .productTypeImage {
    width: 60px;
    height: 60px;

    & path {
      fill: $defaultTextColor;
    }
  }

  .rowActionsContainer {
    position: absolute;
    min-width: 0;
    margin-left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

.archivedContainer {
  background-color: $surfacesLight;
  border: 1px solid $primary20;

  &:hover {
    background: $primary2;
    border: 1px solid $primary10;
  }

  .tag {
    background-color: $white100Base;
  }
}

.documentTypeFooter {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $defaultTextColor;
  }
}

.autoDetectionLabel {
  position: absolute;
  left: -$borderSize;
  top: -$borderSize;

  &:hover {
    > path:first-child {
      fill:  $green100Base;
      stroke: $green100Base;
    }
  }
}

@include for-tablet-and-mobile-only {
  .container {
    padding: 24px;
  }

  .rowActionsContainer {
    display: none;
  }
}
