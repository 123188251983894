.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;

  .label {
    margin-bottom: 8px;
  }
}
