@use 'styles/variables' as *;
@use '../../components/Auth/Variables.module.scss' as *;

.subTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $typo60;
  opacity: 1;

  .subTitlePhone {
    color: $white100Base;
    font-weight: bold;
  }
}
