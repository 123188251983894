@use 'styles/variables' as *;

.container {
  display: flex;
  align-items: center;

  .iconContainer {
    margin-left: -1px;
    width: 52px;
    height: 52px;
    border: 1px solid $primary10;
    box-sizing: border-box;
    padding: 14px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;

    .checkedIcon {
      stroke: $secondary100Base;
    }

    .copyIcon {
      > rect {
        stroke: $secondary100Base;
      }

      > path {
        fill: $secondary100Base;
      }
    }

    &:hover {
      border: 1px solid $secondary100Base;
      background-color: $secondary10;
      z-index: 10;
    }
  }

  .inputContainer {
    padding: 0;
    z-index: 9;

    .input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0;
    }
  }
}
