@use 'styles/variables' as *;
@use 'styles/mixins' as *;
@use 'styles/break-points' as *;

.detailsHeader {
  width: 100%;
  position: relative;
}

.titleSection {
  display: flex;
  align-items: center;
  padding-right: 40px;
  width: 100%;
}

.title {
  @include headerTitle;
}

.subtitleSectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0 24px 0;
}

.subtitleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.actionInformation {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > p {
    margin-left: 8px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $primary80;
    white-space: nowrap;
  }

  order: 2;
}

.actionsButton {
  border-radius: 4px;
  margin: 0 12px 0 4px;
  width: 44px;
  height: 44px;

  > button {
    width: 44px;
    height: 44px;
    opacity: 1;
  }
}

.skeletonSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.skeletonSubtitleSection {
  @extend .skeletonSection;

  margin: 8px 0 24px 0;
}

.subtitleTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  order: 3;
}

.beforeSubtitleTags {
  order: 1;
  margin: 0 12px 0 0;
}

.questionIcon {
  margin-left: 12px;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.skeletonSubtitleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skeletonSubtitle {
  display: flex;
  align-items: center;
}

.titleTooltip {
  & > div {
    width: 500px;
    word-break: break-word;
    overflow-wrap: anywhere;
    white-space: normal;
    text-align: unset;
  }
}

.actionName {
  display: inline;
}

.actionNameShort {
  display: none;
}

.tagsContainer {
  margin-left: 8px;
}

.tagsContainer .clickToCopy {
  display: none;
}

@include for-tablet-and-mobile-only {
  .clickToCopy {
    display: none;
  }

  .tagsContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tagsContainer .clickToCopy {
    display: flex;
  }

  .subtitleTags {
    > div {
      height: 24px;
    }
  }

  .actionName {
    display: none;
  }

  .actionNameShort {
    display: inline;
  }

  .subtitleSectionContainer {
    margin: 8px 0 24px;
  }
}

@include for-mobile-only {
  .title {
    font-size: 22px;
    line-height: 28px;
  }

  .subtitleSection {
    flex-direction: column-reverse;
    gap: 12px;
    height: 84px;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
  }

  .subtitleTags {
    margin: 0;

    > div {
      height: 24px;
    }
  }

  .subtitleSectionContainer {
    margin: 4px 0 0;
  }

  .tagsContainer {
    margin: 0 0 20px;
  }
}
