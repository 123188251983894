@use 'ContextualView/ContextualView.module' as *;
@use 'styles/mixins' as *;
@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.mainLayoutContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  position: relative;

  @include for-tablet-and-mobile-only {
    flex-flow: column nowrap;
    justify-content: stretch;
  }
}

@mixin content {
  flex: 1 1 auto;
  position: relative;
  transition: transform 0.5s ease-in-out;

  & > .contentContainer {
    &:after {
      content: '';
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: background-color 0.5s ease-in-out;
      z-index: $tableHeadStickyZIndex + 1;
    }
  }

  @include for-tablet-and-mobile-only {
    min-height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    width: 100vw;
  }
}

.dynamicContentMoved {
  @include content;

  & > .contentContainer {
    &:after {
      background-color: rgba(146, 149, 162, 0.4);
    }
  }
}

.dynamicContent {
  @include content;

  & > .contentContainer {
    &:after {
      background-color: rgba(146, 149, 162, 0);
      pointer-events: none;
    }
  }
}

.contentContainer {
  @include overflowOverlayY;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: auto;
}

.contextualViewWrapper {
  @include overflowOverlayY;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  overflow-x: hidden;
}

.overlayContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparentBackdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.backdrop {
  z-index: 999 !important;

  @include for-desktop-only {
    display: none;
  }
}
