@use 'styles/variables' as *;

$disabledLinkColor: #375e86;

.nestedLinksContainerExpanded {
  max-height: 220px;
  width: 100%;
  display: block;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.nestedLinksContainerCollapsed {
  @extend .nestedLinksContainerExpanded;
  height: auto;
  max-height: 0px;
}

.nestedItem {
  display: block;
  padding: 8px 0 8px 72px;
  line-height: $pLineHeight;
  font-size: $pFontSize;
  color: $contrastSurfacesTextColor;
  cursor: pointer;
  font-weight: $normalFontWeight;
  transition: all 0.15s ease-in-out;
  font-family: $fontFamilyMonsterrat;
  color: $darkBlue20;

  &:hover {
    opacity: 1;
    color: $surfacesPrimary;
    background-color: $darkBlue120;
  }
}

.activeNestedItem {
  color: $contrastSurfacesAciveTextColor;
  opacity: 1;
  @extend .nestedItem;

  &:hover {
    color: $contrastSurfacesAciveTextColor;
  }
}

.disabledNestedItem {
  @extend .nestedItem;

  color: $disabledLinkColor;
  cursor: default;

  &:hover {
    background-color: transparent;
    color: $disabledLinkColor;
  }
}
