@use 'styles/variables' as *;

.tableHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(15, 35, 66, 0.1);
  background-color: $white100Base;
}

.columns {
  text-transform: uppercase;
  font-weight: bold;
  color: $subtextColor;
  font-size: $tableHeadFontSize;
  line-height: $tableHeadLineHeight;
  letter-spacing: $defaultLetterSpacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
