@use 'styles/variables' as *;
@use '../Variables.module.scss' as *;

.container {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $typo60;
}

.fakeLink {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $electricBlue100Base;
  cursor: pointer;

  &:hover {
    color: $electricBlue80;
  }
}
