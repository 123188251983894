.container {
  display: flex;
  flex-direction: row;

  & > div {
    &:not(:last-child) {
      margin-right: 36px;
    }
  }
}
