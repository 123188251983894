@use 'styles/variables.scss' as *;
@use 'styles/break-points' as *;

.container {
  display: flex;
  flex-direction: column;

  .header {
    padding-left: 45px;
    padding-right: 45px;
    margin-bottom: 16px;
  }

  @include for-tablet-and-mobile-only {
    .header {
      padding-right: 20px;
    }
  }
}

.uploadFileContainer {
  flex: 1;
  height: 100%;

  > label {
    height: 100%;
    justify-content: center;
  }
}

.body {
  padding: 0 45px 24px 45px;
}

.secondaryTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $dark70;
  gap: 4px;
}

@include for-tablet-and-mobile-only {
  .container {
    .header {
      margin-bottom: 20px;
      padding-left: 20px;
    }
  }

  .body {
    padding: 0 20px 16px;
  }
}
