@use 'styles/variables' as *;

.header {
  font-weight: 700;
  font-size: 36px;
  justify-content: space-between;
  line-height: 44px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}

.productionKeysTable {
  margin-bottom: 48px;
}

.tooltip {
  text-align: center;
}

.ipWhitelistHeader {
  margin-top: 48px;
}

.tooltip {
  text-align: center;
}

.questionIcon {
  margin-left: 10px;
  margin-right: auto;
}
