@use 'styles/break-points' as *;
@use 'styles/variables' as *;

.container {
  display: none;

  @include for-tablet-and-mobile-only {
    display: flex;
    min-height: 60px;
    max-height: 60px;
    background-color: $dark100;
    overflow: hidden;
    transition: none;
    padding: 12px 12px 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
  }
}

.burgerMenuIcon {
  display: block;
  min-width: 28px;
  min-height: 28px;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: $white100Base;
}
