@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.noResultsFoundContainer {
  margin-top: 80px;
}

.noItemsContainer {
  margin-top: 60px;
}

.noItemsButton {
  margin-top: 12px;
}

.noItemsSubtitle {
  margin: 8px 0 24px 0;
}

.noItemsTitle {
  text-align: center;
  color: $primary80;
  font-weight: $boldFontWeight;
  margin-top: 24px;
}

.tableLayoutSearchWithFilters {
  margin-bottom: 24px;
}

.searchNotFound {
  margin-top: 24px;
}

.fullHeightContainer {
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.tableWrapperWithFooter {
  overflow: hidden;
  flex: 1 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-flow: column;
}

.headerContainer {
  margin-top: 36px;
}

.tableContainer {
  margin-bottom: 36px;
}

.headerContainer, .tableContainer {
  padding: 0 55px;

  &__embedded {
    padding: 0;
  }
}

@include for-tablet-and-mobile-only {
  .headerContainer, .tableContainer {
    padding: 0 16px;
  }
}
