@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  position: relative;
  width: 980px;
  height: 100%;
  padding: 36px 55px 60px 55px;
}

@include for-tablet-and-mobile-only {
  .container {
    width: 100vw;

    padding: 28px 16px;
  }
}

.closeImage {
  width: 36px;
  height: 36px;
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 36px;
  stroke: $primary80;
}

@include for-mobile-only {
  .closeImage {
    top: 32px;
  }
}
