@use 'styles/variables' as *;

.link {
  color: $electricBlue100;
  text-decoration: none;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: $electricBlue80;
  }
}
