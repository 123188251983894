@use "styles/variables" as *;
@use '../../../../Auth/Variables.module.scss' as *;

.advanceSettingsContainer {
  display: flex;
  cursor: pointer;
  width: 100%;
  margin: 28px 0 4px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  &:before, &:after {
    content: '';
    border-top: 1px solid $borderAndSeparatorsColor;
    display: block;
    flex: 1;
  }
}

.advanceSettingsTitle {
  color: $dark70;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.collapsibleContainer {
  > span p {
    margin: 20px 0 0 0;
  }
}

.processingModeContainer {
  margin: 16px 0 0;
}

.useForAutoDetectSwitchContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 20px;

  .switch {
    margin: 0 12px 0 0;
  }
}

.switchLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: $primary100Base;
}
