@use 'styles/variables' as *;

.link {
  text-decoration: none;
  color: inherit;
}

.disabledLink {
  cursor: default;
}

.coloredLink {
  cursor: pointer;

  &:not(.disabledLink) {
    &:hover {
      color: $secondary100Base;
    }
  }
}
