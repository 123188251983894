@use 'styles/variables' as *;

.tableStub {
  display: flex;
  flex-direction: column;
}

.tableStubColumns {
  display: flex;
  flex-direction: row;
}

.tableStubColumn {
  padding: 12px 10px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  color: $primary40;
}

.tableStubRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;

  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;

  border-bottom: 1px solid $borderAndSeparatorsColor;

  &:first-child {
    border-top: 1px solid $borderAndSeparatorsColor;
  }
}
