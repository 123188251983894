@use 'styles/variables' as *;
@use '../../Variables.module.scss' as *;

.input {
  background: $dark100Base;
  border-radius: 4px;
  font-style: normal;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $white100Base;
}

.passwordShown .input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
}

.passwordHidden .input {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.1em;

  @media (max-width: $mobileMaxWidth) {
    font-size: 14px;
    line-height: 20px;
  }
}

.labelInnerContainer {
  background: $dark100Base;
  border: 1px solid $dark80;
  border-radius: 4px;
}

.container {
  label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $white100Base;
  }

  input {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.1em;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $white100Base;
    padding: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-internal-autofill-previewed,
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px $dark100Base inset !important;
      -webkit-text-fill-color: $white100Base !important;
    }
  }

  .inputFocus {
    border: 1px solid $electricBlue80 !important;
  }

  .inputError {
    border: 1px solid $red100Base;
  }

  .labelInnerContainer {
    .eyeButton {
      background-color: $typo60;
    }

    &:hover {
      border: 1px solid $dark70;
    }
  }
}
