@use 'styles/variables' as *;

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitleWithTooltip {
  display: flex;
  align-items: center;
  min-height: 28px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-right: 8px;
}

.noItemsAddedSection {
  margin-top: 60px;
}

.searchInputContainer {
  width: 420px;
  margin-bottom: 24px;
}
