@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  display: flex;
  flex-direction: column;
}

.headerContainer {
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  @include for-tablet-and-mobile-only {
    margin-bottom: 16px;
    justify-content: space-between;
  }
}

.fullScreenButtonTextView {
  display: inline;

  @include for-tablet-and-mobile-only {
    display: none;
  }
}

.title {
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  color: $primary100Base;
  margin-right: 12px;

  @include for-tablet-and-mobile-only {
    font-size: 16px;
    line-height: 24px;
  }
}

.fullScreenButton {
  color: $electricBlue100;
}

.disabledFullScreenButton {
  color: $subtextColor;
}

.filePreview {
  flex: 1;
  border: 8px solid $electricBlue10;
  border-radius: 6px;
}

.fullScreenPreview {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  border: 0;
}

@include for-tablet-and-mobile-only {
  .fullScreenPreview {
    position: fixed;
  }
}
