@use 'styles/variables' as *;

.accountStatusSection {
  padding: 24px 0 36px 0;
  border-bottom: 1px solid rgba(15, 35, 66, 0.1);
}

.accountStatusSectionContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;

  & > h5 {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: 400;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    color: $headerTextColor;
  }
}

.editPermissionGroupsButton {
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.accountStatusSectionLabel {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $headerTextColor;
}

.resendInviteButton {
  margin-right: 12px;
  margin-left: auto;
}

.firstNameInput {
  padding-top: 0;
}
