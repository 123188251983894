@use 'styles/variables' as *;

.parentValue {
  color: $subtextColor;
};

.collapsibleContainer {
  background-color: $primary2;
}

.childNameCell {
  padding-left: 10px;
}

.arrowIcon {
  > path {
    stroke: $defaultTextColor;
  }
}

.arrowIconExpanded {
  transform: rotate(180deg);
}
