@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.title {
  font-weight: $boldFontWeight;
  font-size: $h5FontSize;
  line-height: $h5LineHeight;
  color: $primary100Base;
}

.showJsonSwitchContainer {
  display: flex;
  align-items: center;
}

.showJsonSwitch {
  margin: 0 12px 0 0;
}

.showJsonTitle {
  font-family: $fontFamilyMonsterrat;
  font-size: $pFontSize;
  font-weight: $normalFontWeight;
  line-height: $pLineHeight;
  letter-spacing: 0;
  white-space: nowrap;
  color: $primary40;
}

.showJsonTitle.active {
  color: $primary100Base;
}

@include for-tablet-and-mobile-only {
  .container {
    margin-bottom: 10px;
  }

  .title {
    font-size: $h6FontSize;
  }
}
