@use 'styles/variables' as *;

.restrictPermissionsContainer {
  padding: 44px 55px;
  height: 100%;
}

.restrictPermissionsHeader {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h2FontSize;
  line-height: $h2LineHeight;
  color: $headerTextColor;
}

.restrictPermissionsBody {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: 60px;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  text-align: center;
  color: $headerTextColor;
  margin-top: 36px;
}

.description {
  @extend .title;
  font-weight: $normalFontWeight;
  color: $defaultTextColor;
  margin-top: 0;
}

.restrictPermissionsButton {
  width: 200px;
}

.buttonContainer {
  display: flex;
  margin-top: 36px;
  & > button {
    width: 180px;
  }
}

.goBackButton {
  margin-left: 12px;
}
