@use 'styles/variables' as *;
@use '../../../Auth/Variables.module.scss' as *;

.tooltip {
  position: fixed;
  background-color: $white100Base;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  z-index: 999;
  max-width: 150px;

  .xData, .yData {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $dark100;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $typo60;
  white-space: nowrap;
  margin-right: 12px;
}

.bar {
  width: 8px;
  height: 16px;
  border-radius: 2px;
  background-color: $green100Base;
}
