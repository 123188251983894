@use 'styles/variables' as *;

.statusCheckboxContainer {
  display: flex;
  flex-direction: column;
  margin: 24px 0 36px 0;
}

.checkboxContainer {
  width: fit-content;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 12px;
}

.addEndpointButton {
  margin-right: 12px;
}
