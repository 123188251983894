@use 'styles/variables' as *;
@use 'components/TextInput/TextInput.module' as *;

.container {
  position: relative;
  width: 100%;
}

.calendarInputOverlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  pointer-events: none;
}

.calendarIconContainer {
  flex: 1 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
}

.calendarIconLeftOffset {
  @include inputBox;
  font-size: $pFontSize;
  flex: 0 0 auto;
  visibility: hidden;
}

.calendarIcon {
  margin-right: 20px;

  &:hover:not(.disabledCalendarIcon) {
    & > path {
      stroke: $secondary100Base;
    }
  }
}

.disabledCalendarIcon {
  cursor: default;
}

.loader {
  right: 50px;
}

.form {
  height: 52px;
}

.default {
  height: 44px;
}

.small {
  height: 36px;
}
