@use 'styles/variables' as *;

.container {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  height: 50%;
  border-bottom: 1px solid $dark70;
}

.text {
  color: $white100Base;
  margin: 0 12.5px;
  font-size: $smallFontSize;
  font-weight: $normalFontWeight;
  line-height: $smallLineHeight;
  letter-spacing: 0px;
}
