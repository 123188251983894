@use 'styles/variables' as *;
@use 'styles/break-points' as *;

$popupZIndex: 200;

.popupContent {
  position: relative;
  padding: 0 55px 48px;

  @include for-mobile-only {
    padding: 0 16px 32px;
  }
}

.popupContentWithTopMargin {
  margin-top: 24px;

  @include for-mobile-only {
    margin-top: 20px;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(146, 149, 162, 0.4);
  z-index: $popupZIndex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
