@use 'styles/variables' as *;

.notFoundContainer {
  margin-top: 36px;

  & > h4 {
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $h5FontSize;
    line-height: $h5LineHeight;
    color: $headerTextColor;
    margin-bottom: 16px;

    & > span {
      color: $headerTextColor;
    }
  }

  & > ul {
    & > li {
      padding-bottom: 16px;
      & > p {
        font-style: normal;
        font-weight: $normalFontWeight;
        font-size: $pFontSize;
        line-height: $pLineHeight;
        color: $defaultTextColor;
        display: flex;
        align-items: center;

        &::before {
          content: '•';
          color: $secondary100Base;
          font-weight: $boldFontWeight;
          line-height: $smallLineHeight;
          margin: 0 8px 4px 0;
        }
      }
    }
  }
}

.smallNotFoundContainer {
  & > ul {
    & > li {
      & > p {
        font-size: $smallFontSize;
        line-height: $smallLineHeight;
      }
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
}
