.overflowedText {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fullText, .overflowedTextWithBreakWords:hover {
  overflow: auto;
  white-space: pre-wrap;
  text-overflow: clip;
  word-break: normal;
  overflow-wrap: break-word;
}

.twoLines {
  &.fullText {
    -webkit-line-clamp: 5;
  }

  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  text-overflow: initial;
  overflow-wrap: break-word;
}
