
@use "styles/variables" as *;

.imagePreview {
  background: rgba(235, 241, 254, 0.40);
  flex: 1 1 auto;
  height: 0;
  border-radius: 4px;
  border: 1px solid #e7e9ec;
}
