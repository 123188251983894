.pieChartElement {
  cursor: pointer;
  transition: transform 0.1s linear;

  &:hover {
    transform: scale(1.01);
  }
}

.tooltip {
  transform: translate(0, calc(-100% - 10px));
}
