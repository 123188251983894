@use "styles/variables" as *;
@use "styles/break-points" as *;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 61px);
  min-height: 784px;
  padding: 36px 55px;
}

.spacedDetailsHeaderTitleSection {
  justify-content: space-between;
}

.detailsHeaderActionsContainer {
  margin-left: 12px;
}

.rightPositionedActionsButton {
  border: 1px solid $borderAndSeparatorsColor;
  margin: 0 0 0 12px;
}

.reProcessButton {
  display: flex;
  align-items: center;
  padding-left: 14px;
  width: 148px;
  color: $electricBlue100;
  border-color: $electricBlue100;

  & > svg {
    margin-right: 6px;

    > path {
      stroke: $electricBlue110;
    }
  }

  &:disabled {
    & > svg {
      & > path {
        stroke: $subtextColor;
      }
    }
  }
}

.exportResultsButton {
  margin-left: auto;
}

.resultContainer {
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
  margin-top: 36px;

  $margin: 48px;

  .column {
    width: calc(50% - #{$margin} / 2);
    height: 100%;

    &:first-child {
      margin-right: $margin;

      @include for-tablet-and-mobile-only {
        position: relative;
      }
    }
  }
}

.exportResultsItem, .detailsHeaderActionsButton {
  display: none;
}

.saveDocumentTypePanel {
  margin-top: 8px;
}

.titleSectionClassName {
  padding: 0;
  justify-content: space-between;
  width: 100%;
}

@include for-tablet-and-mobile-only {
  .detailsHeaderActionsContainer {
    margin-left: 4px;
  }

  .container {
    padding: 16px 16px 24px;
    height: auto;
  }

  .resultContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 30px;
    margin-top: 24px;

    $margin: 48px;

    .column {
      width: 100%;
      height: auto;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  .preview {
    min-height: 400px;
    max-height: 400px;
  }

  .tagsContainer {
    flex-direction: row-reverse;
  }
}

@include for-mobile-only {
  .container {
    padding-top: 26px;
  }

  .exportResultsItem {
    display: block;
  }

  .detailsHeaderTitle {
    display: none;
  }

  .exportResultsButton {
    display: none;
  }

  .tagsContainer {
    flex-direction: row-reverse;
  }

  .detailsHeaderActionsButton {
    display: block;
    position: absolute;
    top: -10px;
    right: 0;
    margin: 0;
    border-radius: 4px;
    border: 1px solid $primary10;
  }
}
