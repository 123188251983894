@use 'styles/mixins' as *;
@use 'styles/variables' as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include headerTitle;
}
