.fileNameCell {
  padding: 16px 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.fileNameContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.resultCell {
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
}
