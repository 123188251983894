@use 'styles/variables' as *;

$lgBtnPadding: 15px 24px;
$mdBtnPadding: 10px 20px;
$smBtnPadding: 9px 16px;

$mdBtnWithIconPadding: 10px 20px 10px 16px;

$lgBtnWidthDefault: 180px;
$mdBtnWidthDefault: 140px;
$smBtnWidthDefault: 100px;

$lgBtnHeight: 52px;
$mdBtnHeight: 44px;
$smBtnHeight: 36px;

$primaryBtnBackgroundColor: $electricBlue100;
$primaryBtnBackgroundColorHover: $electricBlue110;
$primaryBtnBackgroundColorDisabled: $primary6;
$primaryBtnBackgroundColorLoading: $electricBlue110;

$primaryBtnTextColor: $white100Base;
$primaryBtnTextColorHover: $white100Base;
$primaryBtnTextColorDisabled: $subtextColor;
$primaryBtnTextColorLoading: $white100Base;

$secondaryBtnBackgroundColor: transparent;
$secondaryBtnBackgroundColorHover: $electricBlue10;
$secondaryBtnBackgroundColorDisabled: transparent;

$secondaryBtnTextColor: $electricBlue100;
$secondaryBtnTextColorHover: $electricBlue100;
$secondaryBtnTextColorDisabled: $primary40;

$warningBtnBackgroundColor: transparent;
$warningBtnBackgroundColorHover: $red10;
$warningBtnBackgroundColorDisabled: transparent;

$warningBtnTextColor: $defaultErrorColor;
$warningBtnTextColorHover: $defaultErrorColor;
$warningBtnTextColorDisabled: $subtextColor;

$primaryBtnBorder: none;
$secondaryBtnBorder: 1px solid $electricBlue100;
$warningBtnBorder: 1px solid $defaultErrorColor;

$primaryBtnBorderHover: none;
$secondaryBtnBorderHover: 1px solid $electricBlue100;
$warningBtnBorderHover: 1px solid $defaultErrorColor;

$primaryBtnBorderLoading: none;

$primaryBtnBorderDisabled: 1px solid $surfacesLight;
$secondaryBtnBorderDisabled: 1px solid $borderAndSeparatorsColor;
$warningBtnBorderDisabled: 1px solid $borderAndSeparatorsColor;

$secondaryBtnBoxSizing: border-box;
$warningBtnBoxSizing: border-box;

$transitionDuration: 0.25s;
