@use 'styles/variables' as *;

.dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  margin-right: 3px;
  background: $primary80;
  animation: wave 2s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.8s;
  }

  &:nth-child(3) {
    animation-delay: -1.6s;
  }
}

@keyframes wave {
  0%,
  40%,
  100% {
    transform: initial;
    background: $primary80;
  }

  20% {
    transform: translateY(-8px);
    background: rgba($primary80, 0.6);
  }
}
