@use "styles/variables" as *;
@use "styles/break-points" as *;
@use '../../../Auth/Variables.module.scss' as *;

.autoCompletionContainer {
  margin: 20px 0 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.inputWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonLink {
  font-size: $smallFontSize;
  font-weight: $normalFontWeight;
  line-height: $smallLineHeight;
}

.container {
  .submitButton {
    margin-top: 28px;
    min-height: 44px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.hidden {
  display: none;
}

.imagePreview {
  background: rgba(235, 241, 254, 0.40);
  border: 4px dashed rgba($primary20, 0.2);
}

.autoDetectedLabel {
  padding: 7px 10px;
  margin-top: -2px;
}

.documentTypeLoaderIcon > div > div:first-child {
  color: transparent !important; // Spinner component has inline styles so we have to use !important
}

@include for-tablet-and-mobile-only {
  .container {
    flex: 1;
  }

  .file {
    min-height: 120px;
  }
}

.errorMessage {
  color: $defaultErrorColor;
  margin-top: 12px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.showAllFiles {
  text-align: center;
  padding: 8px 0;
  color: $electricBlue110;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-variant-numeric: lining-nums tabular-nums;
}
