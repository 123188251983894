@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.inputContainer {
  padding-top: 0;
  margin-bottom: 36px;
}

.contentMessage {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #3f4f68;
  margin-bottom: 24px;
}

@include for-mobile-only {
  .inputContainer, .contentMessage {
    margin-bottom: 24px;
  }

  .submitButton {
    width: 100%;
  }
}
