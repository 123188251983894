@use 'styles/variables' as *;

.timeRangeDropdown {
  color: $primary100Base;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  cursor: pointer;
  display: flex;
  align-items: center;

  .dropdownIcon {
    margin-left: 3px;
    width: 16px;
    height: 16px;
  }

  &.disabled {
    cursor: default;
  }
}
