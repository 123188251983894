@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.headerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.header {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $primary100Base;
  margin-right: 12px;
}

.tag {
  height: 24px;
}

.valueCell {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-start;
  padding-top: 24px;
  padding-bottom: 24px;
}

.value {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.status {
  margin-left: 12px;
}

.topLine {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.action {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.nameCell {
  padding-left: 0;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
  padding-top: 24px;
  padding-bottom: 24px;
}

@include for-tablet-and-mobile-only {
  .nameCell {
    display: none;
  }

  .nameInValueCell {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $primary80;
    margin-bottom: 16px;
  }

  .valueCell {
    padding-left: 0;
  }
}