@use 'styles/variables' as *;

.codeInput {
  padding-top: 12px;
}

.submitButton {
  margin-top: 36px;
}

.container {
  .timerMessage {
    color: #A4A6B1;

    strong {
      color: $white100Base;
    }
  }
}
