@use 'styles/variables' as *;
@use '../Variables.module.scss' as *;

.rowsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
}

.rowText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: $white100Base;
}

.rowIcon {
  min-width: 24px;
  min-height: 24px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 46px;
  line-height: 60px;
  color: $white100Base;
  margin-bottom: 36px;
}

.highlighted {
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, $lightYellow 0%, $lightPink 100%);
  display: inline-block;
}
