@use 'styles/variables' as *;

.before,
.after {
  content: '';
  position: absolute;
  height: 40px;
  width: 100%;
  pointer-events: none;
}

.before {
  background: linear-gradient(0, rgba(250, 251, 251, 0) 0, $primary2 100%);
  top: 0;
}

.after {
  background: linear-gradient(-180deg, rgba(250, 251, 251, 0) 0%, $primary2 100%);
  bottom: 0;
}

.container {
  position: relative;
  overflow-y: auto;
}
