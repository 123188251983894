@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.actionsButton {
  border-radius: 4px;
  margin: 0 12px 0 4px;
  width: 44px;
  height: 44px;

  > button {
    width: 44px;
    height: 44px;
    opacity: 1;
  }
}

.tabSwitch {
  margin-bottom: 36px;
}

.title {
  display: block;
}

@include for-tablet-and-mobile-only {
  .title {
    font-size: $h4FontSize;
    line-height: $h4LineHeight;
  }
}
