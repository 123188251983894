@use 'styles/variables' as *;

.contextualPopUp {
  margin-top: 6px;
  margin-left: 8px;
  min-width: 166px;
}

.actionPopUp {
  padding: 8px 0;
  width: 166px;
}

.actionDropdownItem {
  padding: 10px 16px 10px 20px;
}

.statusDropdownItemContainer {
  width: 100%;
}

.titleButton {
  display: flex;
  position: relative;
  padding: 16px 20px 16px 46px;
  line-height: 12px;

  &:disabled {
    svg, path {
      stroke: $subtextColor;
    }
  }
}

.downloadImage {
  position: absolute;
  height: 24px;
  width: 24px;
  left: 16px;
  top: 10px;
  stroke: $electricBlue110;
}
