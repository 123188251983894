@use 'styles/variables' as *;

.tools {
  display: flex;
  height: 44px;
  background: rgba(6, 13, 25, 0.8);
  border-radius: 4px;
}

.controlButton {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  stroke: white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  user-select: none;
  font-size: 14px;
  font-weight: $normalFontWeight;
  width: 55px;
}

.disabled {
  stroke: $primary40;
  color: $primary40
}

.zoomIcon {
  @extend .controlButton;
  width: 40px;
}
