@use 'styles/variables' as *;
@use '../Variables.module.scss' as *;

.navLink {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $electricBlue100Base;
  transition: all 0.25s ease-in-out;
  text-decoration: none;

  &:hover {
    color: $electricBlue80;
  }
}
