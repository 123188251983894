@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.dataExtractionResultInfoPanel {
  display: flex;
  flex-direction: column;
  background: $electricBlue10;
  padding: 24px 30px;
  border-radius: 6px;
}

.dataExtractionResultInfoPanelFields {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 14px;
  column-gap: 30px;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
  border-bottom: 1px solid $borderAndSeparatorsColor;
}

.withoutBorderField {
  border-bottom: 0;
  padding-bottom: 0;
}

.fieldLabel {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
}

.fieldValue {
  margin-top: 2px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
}

.fieldLink {
  @extend .fieldValue;
  text-decoration: none;

  &:hover {
    color: $secondary100Base;
  }
}

.dotFallingLoader {
  margin-top: 2px;
}

@include for-tablet-and-mobile-only {
  .dataExtractionResultInfoPanelFields {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 14px;
    column-gap: 30px;
  }
}