@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.search {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid $primary10;
  height: 44px;
  width: 420px;

  & input {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    border: none;
    outline: none;
    color: $headerTextColor;
    width: 100%;
    margin-left: 8px;

    &::placeholder {
      color: $subtextColor;
      opacity: 1;
    }
  }
}

.searchIcon {
  min-width: 24px;
  min-height: 24px;
}

.clearIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
}

.clearIcon {
  stroke: black;
  width: 16px;
  height: 16px;
  cursor: pointer;

  & path {
    stroke: $headerTextColor;
  }
}

.loader {
  width: auto;
  height: auto;
}

@include for-mobile-only {
  .search {
    width: auto;
  }
}
