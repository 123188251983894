@use 'styles/variables' as *;

.tag {
  margin-top: 8px;
  align-self: flex-start;
}

.container {
  display: flex;
  flex-direction: column;
}

.table {
  margin-top: 24px;
}
