@use 'styles/variables' as *;

.webhookEndpointIntegrationContainer {
  position: relative;
}

.generateNewKeyButton {
  position: absolute;
  top: -8px;
  right: 0;
}

.needHelpSection {
  margin-top: 24px;
}
