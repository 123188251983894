@use 'styles/variables' as *;

.message {
  margin-top: 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
  margin-bottom: 12px;
}

.timerMessage {
  margin-top: 12px;
}
