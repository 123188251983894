@use 'styles/break-points' as *;

.popup {
  width: 260px;
}

.popupContainer {
  left: 10px !important; // overwriting material ui inline styles
  margin-top: 4px;

  @include for-tablet-and-mobile-only {
    left: auto !important;
    right: 16px;
    margin-top: 0;
    top: 12px !important;
  }
}

.root {
  z-index: 99999 !important;
}
