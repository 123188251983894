$mobileMaxWidth: 767px;
$desktopMinWidth: 1160px;

@mixin for-tablet-and-mobile-only {
  @media (max-width: calc($desktopMinWidth - 1px)) { @content; }
}

@mixin for-mobile-only {
  @media (max-width: $mobileMaxWidth) { @content; }
}

@mixin for-desktop-only {
  @media (min-width: $desktopMinWidth) { @content; }
}
