@use 'styles/variables' as *;

$paddingTop: 0;
$paddingRight: 30px;
$paddingBottom: 20px;
$paddingLeft: 30px;

.container {
  padding: $paddingTop $paddingRight $paddingBottom $paddingLeft;
  border: 1px solid $primary10;
  border-radius: 4px;
}

.chart {
  height: 160px;
}

.titleLine {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $primary120;
}

.secondaryTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.hint {
  margin-left: 9px;
  margin-right: auto;
}

.hintTooltip {
  > div {
    max-width: 300px;
    white-space: pre-wrap;
  }
}

.row {
  border-bottom: 1px solid rgba(146, 149, 162, 0.2);
  display: flex;
  justify-content: flex-end;

  &:not(:last-child) {
    margin-bottom: 43px;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}

:export {
  paddingTop: $paddingTop;
  paddingRight: $paddingRight;
  paddingBottom: $paddingBottom;
  paddingLeft: $paddingLeft;
}
