@use 'styles/variables' as *;
@use 'components/NoItems/CommonVariables.module' as *;

.header {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: $primary100Base;
  margin-bottom: 24px;
  margin-top: 48px;
}

.noData {
  padding-top: $noDataTopOffset;
}

.noDataTitle {
  margin-top: 24px;
}
