@use 'styles/variables' as *;

.container {
  cursor: pointer;
  display: inline-flex;
  color: $primary80;
  border: 1px solid $primary10;
  box-sizing: border-box;
  padding: 4px 8px;
  border-radius: 4px;
  background: $primary2;
  height: 24px;
  align-self: self-start;

  svg {
    margin-right: 4px;
    transition: stroke 0.3s ease-in-out, fill 1s ease-in-out;
  }
}

.container:hover {
  border: 1px solid $secondary40;
  background-color: $secondary3;
  color: $secondary100Base;

  .copyIcon {
    height: 16px;
    width: 16px;

    > rect {
      stroke: $secondary100Base;
    }

    > path {
      fill: $secondary100Base;
    }
  }

  .checkedIcon > path {
    stroke: $secondary100Base;
  }
}

.checkedIcon {
  height: 16px;
  width: 16px;

  > path {
    stroke: $green100Base;
  }
}

.copyIcon {
  height: 16px;
  width: 16px;

  > rect {
    stroke: $primary80;
  }

  > path {
    fill: $primary80;
  }
}

.value {
  font-size: $smallFontSize;
  font-weight: $normalFontWeight;
  line-height: $smallLineHeight;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
