@use 'styles/break-points' as *;

.tableRowMobile {
  display: none;
}

@include for-tablet-and-mobile-only {
  .tableRowDesktop {
    display: none;
  }

  .tableRowMobile {
    display: flex;
  }
}
