@use 'styles/variables' as *;

.popupRoot {
  width: 240px;
  max-height: 484px !important;
}

.actionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
}

.selectedActionItem {
  color: $electricBlue100;
}
