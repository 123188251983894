@use '../../styles/variables.scss' as *;

.container {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.label {
  border-radius: 2px;
  border: 1px solid $green20;
  background: $green10;
  padding: 3px 8px;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  color: $green100Base;
}

.text {
  width: auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $primary80;
  font-variant-numeric: lining-nums tabular-nums;
}
