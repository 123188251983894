@use 'styles/variables' as *;
@use 'styles/mixins' as *;
@use '../Auth/Variables.module.scss' as *;

$userCardContainerHeight: 60px;
$imgHeight: 32px;
$imgWidth: 32px;
$userInfoContainerWidth: 200px;
$credentialsFontColor: #0f2342;
$imgContainerMarginRight: 12px;
$userInitilsColor: #ffffff;

.userCardContainer {
  &:not(.noMargin) {
    margin-top: 20px;
  }

  display: flex;
  height: $userCardContainerHeight;
}

.imgContainer {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: $imgContainerMarginRight;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: $userInfoContainerWidth;
  overflow: ellipsis;
  font-family: $fontFamilyMonsterrat;
  color: $white100Base;
  font-weight: $boldFontWeight;
}

.userName {
  font-family: $fontFamilyMonsterrat;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.link {
  @include buttonReset;
}

.linkText {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $electricBlue100Base;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $electricBlue80;
  }
}

.avatarText {
  color: $dark100Base;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

:export {
  userImgBackground: $lightYellow;
}
