@use 'styles/variables' as *;
@use 'components/LeftNav/LeftNavigation.module.scss' as *;
@use 'styles/break-points' as *;

.pageContent {
  max-width: 2200px - $leftNavigationWidthCollapsed;
  flex-grow: 1;
  font-family: $fontFamilyMonsterrat;
}

.pagePaddings {
  padding: 36px 55px 60px 55px;

  @include for-tablet-and-mobile-only {
    padding: 24px 16px;
  }
}
