@use 'styles/variables' as *;
@use './ButtonVariables' as *;

.button {
  border-radius: $borderRadiusDefault;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
  transition: all $transitionDuration ease-in-out;
  outline: 0;

  &.disabled {
    background-color: $surfacesLight;
    color: $subtextColor;
    border: 1px solid $surfacesLight;
    cursor: default;
    transition: none;
  }
}

.largeSize {
  height: $lgBtnHeight;
  padding: $lgBtnPadding;
  min-width: $lgBtnWidthDefault;
  font-size: $pFontSize;
  line-height: $pLineHeight;

  > .loader {
    height: $pLineHeight * 0.5;
  }
}

.mediumSize {
  height: $mdBtnHeight;
  padding: $mdBtnPadding;
  min-width: $mdBtnWidthDefault;
  font-size: $pFontSize;
  line-height: $pLineHeight;

  > .loader {
    height: $pLineHeight * 0.5;
  }
}

.smallSize {
  height: $smBtnHeight;
  padding: $smBtnPadding;
  min-width: $smBtnWidthDefault;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;

  > .loader {
    height: $smallLineHeight * 0.5;
  }
}

.primary {
  color: $primaryBtnTextColor;
  background-color: $primaryBtnBackgroundColor;
  border: $primaryBtnBorder;

  &:hover {
    color: $primaryBtnTextColorHover;
    background-color: $primaryBtnBackgroundColorHover;
    border: $primaryBtnBorderHover;
  }

  &.disabled {
    color: $primaryBtnTextColorDisabled;
    background-color: $primaryBtnBackgroundColorDisabled;
    border: $primaryBtnBorderDisabled;
  }

  &.buttonLoading {
    color: $primaryBtnTextColorLoading;
    background-color: $primaryBtnBackgroundColorLoading;
    border: $primaryBtnBorderLoading;
    cursor: default;
  }

  &:focus:not(.disabled) {
    color: $primaryBtnTextColorHover;
    background-color: $primaryBtnBackgroundColorHover;
    border: $primaryBtnBorderHover;
  }

  &:focus-visible:not(.disabled) {
    color: $primaryBtnTextColorHover;
    background-color: $primaryBtnBackgroundColorHover;
    border: $primaryBtnBorderHover;
  }
}

.secondary {
  color: $secondaryBtnTextColor;
  background-color: $secondaryBtnBackgroundColor;
  box-sizing: $secondaryBtnBoxSizing;
  border: $secondaryBtnBorder;

  &:hover {
    color: $secondaryBtnTextColorHover;
    background-color: $secondaryBtnBackgroundColorHover;
    border: $secondaryBtnBorderHover;
  }

  &.disabled {
    color: $secondaryBtnTextColorDisabled;
    background-color: $secondaryBtnBackgroundColorDisabled;
    box-sizing: $secondaryBtnBoxSizing;
    border: $secondaryBtnBorderDisabled;
  }

  &.buttonLoading {
    color: $secondaryBtnTextColor;
    background-color: $secondaryBtnBackgroundColor;
    box-sizing: $secondaryBtnBoxSizing;
    border: $secondaryBtnBorder;
    cursor: default;
  }

  &:focus:not(.disabled) {
    color: $secondaryBtnTextColor;
    background-color: $secondaryBtnBackgroundColor;
    border: $secondaryBtnBorder;
  }

  &:focus-visible:not(.disabled) {
    color: $secondaryBtnTextColorHover;
    background-color: $secondaryBtnBackgroundColorHover;
    border: $secondaryBtnBorderHover;
  }
}

.withState {
  display: flex;
  align-items: center;

  color: $primary100Base;
  stroke: $primary100Base;
  background-color: $warningBtnBackgroundColor;
  box-sizing: $warningBtnBoxSizing;
  border: 1px solid $primary10;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover,
  &.active {
    color: $secondary100Base;
    stroke: $secondary100Base;
    border: 1px solid $secondary40;
  }
}

.warning {
  color: $warningBtnTextColor;
  background-color: $warningBtnBackgroundColor;
  box-sizing: $warningBtnBoxSizing;
  border: $warningBtnBorder;

  &:hover {
    color: $warningBtnTextColorHover;
    background-color: $warningBtnBackgroundColorHover;
    border: $warningBtnBorderHover;
  }

  &.disabled {
    color: $warningBtnTextColorDisabled;
    background-color: $warningBtnBackgroundColorDisabled;
    box-sizing: $warningBtnBoxSizing;
    border: $warningBtnBorderDisabled;
  }

  &.buttonLoading {
    color: $warningBtnTextColor;
    background-color: $warningBtnBackgroundColor;
    box-sizing: $warningBtnBoxSizing;
    border: $warningBtnBorder;
    cursor: default;
  }

  &:focus:not(.disabled) {
    color: $warningBtnTextColor;
    background-color: $warningBtnBackgroundColor;
    border: $warningBtnBorder;
  }

  &:focus-visible:not(.disabled) {
    color: $warningBtnTextColorHover;
    background-color: $warningBtnBackgroundColorHover;
    border: $warningBtnBorderHover;
  }
}

.fullWidth {
  width: 100%;
}

.formOffset {
  margin-top: 36px;
}

:export {
  primaryDotColor: $surfacesPrimary;
  secondaryDotColor: $secondary100Base;
  warningDotColor: $red100Base;
}