@use '../../../styles/variables.scss' as *;

.legend {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 10px;
}

.legendItem {
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: 0;
}

.legendItemColor {
  min-width: 8px;
  min-height: 8px;
  border-radius: 50%;
}

.legendItemText {
  min-width: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $primary100Base;
}
