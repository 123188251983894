@use "styles/variables" as *;
@use "styles/break-points" as *;

.filesContainer {
  display: flex;
  margin-top: 20px;
  max-height: 160px;
  overflow-y: hidden;
  flex-flow: column;

  &.overflow {
    overflow-y: auto;
  }
}

.fileContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $primary10;
  cursor: pointer;
  padding: 8px 10px;
  width: 100%;

  &:not(.error):hover {
    background: $electricBlue10;
  }

  &.error {
    background: rgba($red100Base, 0.1);
  }
}

.fileIcon {
  flex-basis: 24px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 10px;
}

.fileName {
  color: $primary80;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-right: 10px;
  margin-right: auto;
}

.closeImage {
  display: block;
  margin: 0 10px;
  min-width: 24px;
  min-height: 24px;

  visibility: hidden;

  @include for-tablet-and-mobile-only {
    visibility: visible;
  }

  &.active {
    visibility: visible;
  }

  &.disabled {
    visibility: hidden;
  }
}

.progressBar {
  margin-right: 0;
  margin-left: auto;
  max-width: 180px;
}

.errorIcon {
  margin: 0 0 0 auto;
  background: white;
  border-radius: 50%;

  &::after {
    content: '!';
  }
}

.tooltipBody {
  max-width: 300px;
  overflow-wrap: break-word;
  white-space:normal;
}
