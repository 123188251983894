@use 'styles/variables' as *;

.errorContainer {
  display: flex;
  height: 100vh;
  justify-content: flex-start;
}

.errorInfo {
  height: 100%;
  flex-basis: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 32px;

  & > svg {
    margin-left: 55px;
  }
}

.errorImage {
  height: 100%;
  background-color: $surfacesSidebar;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  & > img {
    position: absolute;
    left: -10%;
    width: 110%;
    height: auto;
  }
}
