@use 'styles/variables' as *;

.header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 36px;
  width: 100%;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  padding-bottom: 16px;
}

.headerTitle {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $h4FontSize;
  line-height: $h4LineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
}

.editButton {
  margin-left: 12px;
  border-radius: 6px;
  padding: 6px 8px;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: $defaultLinkColor;
    background-color: $secondary10;
  }
}

.tableRowLabel,
.tableRowValue {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.tableRowLabel {
  color: $placeholderTextColor;
}

.tableRowValue {
  color: $defaultTextColor;
}
