@use 'styles/variables' as *;

.filterTitle {
  margin-bottom: 0;
  margin-right: 6px;
}

.dateRangePicker {
  margin-bottom: 36px;
}

:export {
  redTagColor: $red100Base;
  blueTagColor: $secondary100Base;
  greenTagColor: $green100Base;
  grayTagColor: $primary80;
}
