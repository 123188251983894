@use 'styles/variables' as *;

.collapsibleContainer {
  background-color: $primary2;
}

.childNameCell {
  padding-left: 20px;
}

.arrowIcon {
  > path {
    stroke: $defaultTextColor;
  }
}

.arrowIconExpanded {
  transform: rotate(180deg);
}
