@use "styles/break-points" as *;

.mainLayoutNotifications {
  transition: left 0.5s ease-in-out;
}

.leftNavigationExpanded {
  left: 280px;
}

.leftNavigationCollapsed {
  left: 72px;
}

.empty {
  pointer-events: none;
}

@include for-tablet-and-mobile-only {
  .mainLayoutNotifications {
    left: 0;
    width: 100vw;

    > div {
      width: 100%;
    }
  }
}
