@use 'components/Button/ButtonVariables' as *;
@use 'styles/variables' as *;

.button {
  padding: $mdBtnWithIconPadding;
  justify-content: center;
}

.actionItem {
  display: flex;
  align-items: center;

  padding-right: 4px;
}

.selectedActionItem {
  @extend .actionItem;
  color: $secondary100Base;
}

.checkIcon {
  margin: -50% 0 -50% auto;
}

.popupRoot {
  margin-top: 2px;
}

.optionWrapper {
  width: 100%;
}

.disabled {
  pointer-events: none;
}
