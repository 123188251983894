@use '../../styles/variables.scss' as *;

.tag {
  cursor: pointer;
  margin-right: 2px;
}

.tooltip > div {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $headerTextColor;
  white-space: nowrap;
  font-family: $fontFamilyMonsterrat;
}
