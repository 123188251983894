@use 'styles/variables' as *;

.container {
  flex: 1;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.dragZoneLabel {
  height: 100%;
  justify-content: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 3px dashed rgba($primary20, 0.3);
  background: rgba(221, 222, 228, 0.10);
  border-radius: 4px;
  transition: 0.25s border ease-in-out;
  transition: 0.1s background-color ease-in-out;
  padding: 16px;
}

.dragOverInputWrapper {
  @extend .inputWrapper;
  border: 3px dashed $secondary100Base;
  background-color: $electricBlue10;
}

.fileInfoContainer {
  @extend .inputWrapper;

  position: relative;
  background-color: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 12px;

  &.noMargin {
    margin-top: 0;
  }
}

.fileInput {
  display: none;
}

.fileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fileDeleteContainer {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
}

.fileDelete {
  width: 24px;
  height: 24px;
}

.fileName {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $dark100;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fileFormat {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
  text-transform: uppercase;
  margin-top: 8px;
}

.textContainer {
  font-family: $fontFamilyMonsterrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $dark100;
  width: 100%;
  text-align: center;
}

.secondaryTextContainer {
  font-family: $fontFamilyMonsterrat;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary40;
  width: 100%;
  text-align: center;
}

.link {
  color: $electricBlue100;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: $defaultLinkHoverColor;
  }
}

.linkDisabled, .linkDisabled:hover {
  cursor: default;
  color: $subtextColor;
}

.inputTitleLink {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $defaultLinkColor;
}

.fileImage > svg {
  min-width: 32px;
  min-height: 32px;
}

.errorMessage {
  color: $defaultErrorColor;
  margin-top: 12px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.errorInputWrapper {
  @extend .inputWrapper;
  border: 1px dashed $defaultErrorColor;
}

.noMargin {
  margin: 0;
}

.container.disabled {
  background: $primary6;

  .textContainer {
    color: $secondary30;
  }

  .secondaryTextContainer {
    color: $primary40;
  }

  svg {
    opacity: 0.3;
  }
}
