@use 'styles/variables' as *;

.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 24px;
}

.eventDate {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary80;
  margin-right: 24px;
}

.tag {
  height: 24px;
}

.label {
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $headerTextColor;
  margin-bottom: 8px;
}

.eventDataPreviewContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.eventDataPreview {
  border: 1px solid $primary10;
  border-radius: 4px;
  background: $surfacesLight;

  :global(.ace_gutter) {
    background: $primary10 !important;
    color: $primary40 !important;
  }
}

.eventSendingData {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
}

.eventDetailsDataFields {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
}

.eventDetailsDataField {
  height: 44px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  padding: 14px 0;
  border-top: 1px solid $borderAndSeparatorsColor;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid $borderAndSeparatorsColor;
  }
}

.eventDetailsDataFieldLabel {
  width: 200px;
  color: $primary40;
  padding: 0 10px;
}

.eventDetailsDataFieldValue {
  color: $primary80;
  padding-left: 10px;
  margin-right: 4px;
}

.actions {
  display: flex;
  flex-direction: row;
}

.resendEventButton {
  margin-right: 12px;

  &:disabled {
    pointer-events: none;
  }
}

.tooltipWrapper {
  cursor: pointer;
}
