@use 'styles/variables' as *;
@use '../Variables.module.scss' as *;

$formBackground: #111E40;
$formBorder: #2F4170;

.container {
  background-color: $dark100Base;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 72px;
}

.secondaryBlockContainer {
  margin-top: 130px;
  max-width: 501px;
}

.mainFormContainer {
  background: $formBackground;
  border: 1px solid $formBorder;
  border-radius: 8px;
  position: relative;

  &::before {
    $size: 316px;

    content: '';
    position: absolute;
    background: $electricBlue80;
    opacity: 0.4;
    filter: blur(200px);
    width: $size;
    height: $size;
    left: calc(-1 * $size / 2);
    top: calc(-1 * $size / 2);
  }
}

.mainFormHeader {
  border-bottom: 1px solid $formBorder;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.logo {
  min-width: 188px;
  min-height: 36px;
}

.title {
  font-style: normal;
  font-weight: 700;
  color: $white100Base;
}

.subTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.6;
  color: $white100Base;
  margin-top: 12px;
}

.formContent {
  margin: 36px 0 0 0;
}

.formFooter {
  margin-top: 36px;
  margin-bottom: 83px;
  display: flex;
  justify-content: center;
}

.notifications {
  left: 0;
  width: 100vw;
}

// tablets and desktop devices
@media (min-width: $tabletMaxWidth) {
  .notifications {
    display: flex;
    align-items: center;

    > div {
      width: 680px;
    }
  }
}

// desktop devices
@media (min-width: $desktopMinWidth) {
  .content {
    padding: 100px 156px 0 156px;
  }

  .title {
    font-size: 36px;
    line-height: 44px;
  }

  .mainFormContainer {
    width: 680px;
  }

  .mainFormBody {
    padding: 36px 88px 60px 88px;
  }
}

// mobile & tablet devices
@media (max-width: $tabletMaxWidth) {
  .content {
    padding: 64px 0 0 0;
    gap: 44px;
  }

  .secondaryBlockContainer {
    display: none;
  }

  .title {
    font-size: 28px;
    line-height: 36px;
  }

  .mainFormContainer {
    width: auto;
  }

  .mainFormBody {
    padding: 36px 88px 60px;
  }

  .mainFormContainer {
    width: 680px;
    min-width: 380px;
  }

  .container {
    height: auto;
  }

  .content {
    padding: 0;
    padding-top: 64px;
    width: 100%;
  }

  .formFooter {
    margin-bottom: 68px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

// mobile devices
@media (max-width: $mobileMaxWidth) {
  .mainFormContainer {
    width: auto;
    min-width: 380px;
  }

  .mainFormBody {
    padding: 36px 24px 60px;
  }

  .formFooter {
    margin-bottom: 28px;
  }
}
