@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.userFilter {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__people {
    > div {
      cursor: pointer;
    }
  }

  &__clearAll {
    margin-left: 8px;
    border: none;
    color: $primary100Base;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-feature-settings: 'tnum' on, 'lnum' on;
    background-color: transparent;
    cursor: pointer;
    transition: 0.25s color ease-in-out;

    &:hover {
      color: $secondary100Base;
    }
  }
}

@include for-tablet-and-mobile-only {
  .userFilter {
    display: none;
  }
}
