@use 'styles/variables' as *;
@use 'components/Tooltip/Common.module' as *;

.tooltip {
  > div {
    background-color: $white100Base;
    border: 1px solid $primary120;
    box-sizing: border-box;
    border-radius: $borderRadiusDefault;
    padding: 8px 12px;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $headerTextColor;
    white-space: nowrap;
    max-width: none;

    &:global(.MuiTooltip-tooltipPlacementTop) {
      margin-top: $defaultTopMargin;
    }

    &:global(.MuiTooltip-tooltipPlacementBottom) {
      margin-top: $defaultBottomMargin;
    }

    > span {
      color: $white100Base;

      &::before {
        border: 1px solid $primary120;
      }
    }
  }
}

.leftAlignment {
  & > div {
    text-align: left;
  }
}

.rightAlignment {
  & > div {
    text-align: right;
  }
}
