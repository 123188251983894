@use 'styles/variables' as *;

.container {
  margin-top: 24px;
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.apiKey {
  margin-bottom: 24px;
  margin-top: 24px;
}

.list {
  list-style-type: disc;
  list-style-position: inside;

  li {
    padding-left: 12px;
  }
}

.closeButton {
  margin-top: 36px;
}
