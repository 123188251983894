@use 'styles/variables' as *;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  width: 100%;
  padding: 14px 0;
  color: $dark100;
  font-size: $pFontSize;
  font-weight: $boldFontWeight;
  line-height: $pLineHeight;
  text-align: center;
  background-color: $white100Base;
  border-radius: 4px;
  border: none;

  &:hover {
    cursor: pointer;
    background-color: $electricBlue20;
  }
}

.icon {
  margin-right: 8px;
}
