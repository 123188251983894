@use 'styles/variables' as *;

.firstName {
  padding-top: 0;
}

.createAccount {
  margin-top: 36px;
}

.fullNameContainer {
  display: flex;
  gap: 24px;
}

.footer {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $white100Base;
  margin-top: 24px;

  > div {
    display: inline-block;
  }
}
