@use 'styles/variables' as *;
@use '../../Variables.module.scss' as *;

.input {
  background: $dark100Base;
  border: 1px solid $dark80;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $white100Base;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-internal-autofill-previewed,
  &:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px $dark100Base inset !important;
    -webkit-text-fill-color: $white100Base !important;
  }

  &:hover {
    border: 1px solid $dark70;
  }

  &:focus {
    border: 1px solid $electricBlue80 !important;
  }

  &.error {
    border: 1px solid $red100Base;
  }
}

.inputContainer {
  label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: $white100Base;
  }
}
