@use 'styles/variables' as *;

$lineColor: $primary10;
$lineOpacity: 0.5;

$textColor: $primary40;
$textSize: 12px;
$textWeight: 500;

$chartLineColor: $electricBlue110;
$chartLineWidth: 1px;
$chartDotWidth: 1.5px;
$chartPointColor: $white100Base;

$gradientFrom: $electricBlue100;
$gradientTo: rgba(25, 96, 246, 0.00);

.axisXLabel {
  font-family: $fontFamilyMonsterrat;
  font-weight: $textWeight;
  font-size: $textSize;
  color: $primary40;
}

:export {
  gridLineColor: $lineColor;
  gridLineOpacity: $lineOpacity;

  textColor: $textColor;
  fontFamily: $fontFamilyMonsterrat;
  textSize: $textSize;
  textWeight: $textWeight;

  lineColor: $chartLineColor;
  lineWidth: $chartLineWidth;
  chartDotWidth: $chartDotWidth;
  pointColor: $chartPointColor;

  gradientFrom: $gradientFrom;
  gradientTo: $gradientTo;

  lineColorDisabled: $primary20;

  gradientFromDisabled: rgba(241, 241, 241, 0.4);
  gradientToDisabled: rgba(241, 241, 241, 0);
}
