$mobileMaxWidth: 767px;
$tabletMaxWidth: 1200px;
$desktopMinWidth: 1201px;

$typo60: #A5A8AF;
$primaryText40: #9FA7B3;
$electricBlue100Base: #1960F6;
$dark100Base: #0C111D;
$dark80: #3A4658;

$lightYellow: #FFC312;
$lightOrange: #EE9236;
$lightPink: #FF70AF;

:export {
  mobileMaxWidth: $mobileMaxWidth;
  desktopMinWidth: $desktopMinWidth;
}
