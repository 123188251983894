@use 'styles/break-points' as *;

.submitButton {
  margin-top: 36px;
}

@include for-mobile-only {
  .submitButton {
    margin-top: 24px;
    width: 100%;
  }
}
