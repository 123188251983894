@use 'styles/variables' as *;
@use 'components/BillingDashboard/Overview/OverviewVariables' as *;

$columnSpaceSize: 54px;

.columns {
  display: flex;
}

.leftSideContainer {
  width: 75%;
  margin-right: $columnSpaceSize;
}

.rightSideContainer {
  width: calc(25% - #{$columnSpaceSize});
}

.statistics {
  margin-top: 14px;
}

.companyInformation {
  margin-bottom: 48px;
}
