@use 'styles/variables' as *;

$disabledLinkColor: #375e86;

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:not(.disabledLink):hover {
    background-color: $surfacesSidebar;
  }

  &:hover .subLinkList {
    visibility: visible;
  }
}

.disabledLink {
  cursor: default;
}

.icon {
  margin: 18px 24px 17px 24px;
  min-height: 24px;
  min-width: 24px;
  transition: fill 0.15s ease-in-out;
}

.iconActive {
  @extend .icon;
}

.fill {
  &.icon {
    fill: $contrastSurfacesIconColor;
  }

  &.iconActive {
    fill: $contrastSurfacesActiveIconColor;
  }

  &.disabledIcon {
    fill: $disabledLinkColor;
  }
}

.stroke {
  &.icon {
    stroke: $contrastSurfacesIconColor;
  }

  &.iconActive {
    stroke: $contrastSurfacesActiveIconColor;
  }

  &.disabledIcon {
    stroke: $disabledLinkColor;
  }
}

.title {
  width: 208px;
  transition: color 0.15s ease-in-out;
  overflow: hidden;
  color: $contrastSurfacesTextColor;
  white-space: nowrap;
  letter-spacing: $defaultLetterSpacing;
  text-transform: uppercase;
  font-weight: $boldFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-family: $fontFamilyMonsterrat;
}

.titleActive {
  @extend .title;
  color: $contrastSurfacesAciveTextColor;
}

.disabledTitle {
  @extend .title;

  color: $disabledLinkColor;
}

.nestedLinksContainerExpanded {
  max-height: 200px;
  width: 100%;
  display: block;
  padding-left: 72px;
  overflow: hidden;
  transition: max-height 0.25s ease-in-out;
}

.nestedLinksContainerCollapsed {
  @extend .nestedLinksContainerExpanded;
  height: auto;
  max-height: 0;
}

.nestedItem {
  margin: 0 0 15px 0;
  line-height: $pLineHeight;
  font-size: $pFontSize;
  color: $contrastSurfacesTextColor;
  opacity: 0.75;
  cursor: pointer;
  font-weight: $normalFontWeight;
  transition: all 0.15s ease-in-out;
  font-family: $fontFamilyMonsterrat;
  &:hover {
    opacity: 1;
  }
}

.activeNestedItem {
  @extend .nestedItem;
  color: $contrastSurfacesAciveTextColor;
  opacity: 1;
}

.dropdownIconClosed {
  margin-right: 12px;
  transition: all 0.15s ease-in-out;
  stroke: $contrastSurfacesIconColor;
}

.dropdownIconOpened {
  @extend .dropdownIconClosed;
  transform: rotate(180deg);
}

.disabledDropdownIcon {
  stroke: $disabledLinkColor;
}

.activeDropdownIcon {
  stroke: $contrastSurfacesActiveIconColor;
}

.subLinkList {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 72px;
  background-color: $primaryDark;
  width: 231px;
  border-top-right-radius: $borderRadiusDefault;
  border-bottom-right-radius: $borderRadiusDefault;
  color: $darkBlue20;
}

.subLink {
  display: block;
  padding: 8px 11px 8px 24px;
  font-weight: $normalFontWeight;
  font-style: normal;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  transition: 0.25s color ease-in-out, 0.25s background-color ease-in-out;

  &:hover {
    background-color: $primaryDark;
    color: $contrastSurfacesTextColor;
  }
}

.activeSubLink {
  color: $yellow;
  @extend .subLink;

  &:hover {
    color: $yellow;
  }
}

.subTitleLink {
  @extend .subLink;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-weight: $boldFontWeight;
  padding: 21px 12px 22px 23px;
  border-top-right-radius: $borderRadiusDefault;
  border-bottom-right-radius: $borderRadiusDefault;
  color: $surfacesPrimary;
  letter-spacing: $defaultLetterSpacing;

  &:hover {
    background-color: $primaryDark;
    color: $contrastSurfacesTextColor;
  }
}

.subTitleLinkNested {
  @extend .subTitleLink;

  &:hover {
    background-color: transparent;
    cursor: default;
  }
}

.activeSubTitleLink {
  color: $yellow;
  @extend .subTitleLink;
  @extend .activeSubLink;

  &:hover {
    color: $yellow;
  }
}

.nestedLinksList {
  width: 100%;
  padding-bottom: 12px;
}

.nestedLinkItem {
  &:last-child {
    margin-bottom: 16px;
  }
}
