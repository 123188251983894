@use 'styles/break-points' as *;

.documentTypeHeader {
  margin-bottom:36px;
}

.noItems {
  margin-top: 60px;
}

@include for-mobile-only {
  .documentTypeHeader {
    margin-bottom: 24px;
  }
}
