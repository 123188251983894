@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.closeBtn {
  border: none;
  background-color: transparent;
  height: 36px;
  width: 36px;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  & svg {
    width: 100%;
    height: auto;

    & path {
      stroke: $defaultTextColor;
    }

    @include for-mobile-only {
      width: 24px;
      height: 24px;
    }
  }

  &__disabled {
    cursor: default;

    svg path {
      stroke: $primary20;
    }
  }
}
