@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.titleContainer {
  display: flex;
  width: 100%;
}

.title {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size:$h4FontSize;
  line-height: $h4LineHeight;
  color: $headerTextColor;
  margin-right: 4px;
}

.showJsonSwitchContainer {
  display: flex;
  align-items: center;
}

.showJsonTitle {
  font-family: $fontFamilyMonsterrat;
  font-size: $pFontSize;
  font-weight: $normalFontWeight;
  line-height: $pLineHeight;
  letter-spacing: 0;
  white-space: nowrap;
  color: $primary40;
}

.active {
  color: $primary100Base;
}

@include for-tablet-and-mobile-only {
  .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .titleContainer {
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .showJsonSwitchContainer {
    margin-left: -8px;
  }

  .title {
    font-size: $h6FontSize;
    line-height: $h5LineHeight;
  } 
}
