@use 'styles/variables' as *;

.tableContainer {
  overflow: auto;
}

.noDataText {
  padding-left: 0;
  color: $subtextColor;
}
