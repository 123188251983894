.avatar {
  margin-top: -50%;
  margin-bottom: -50%;
  margin-right: 8px;

  > div {
    width: 28px;
    height: 28px;
  }
}

.cell {
  padding-top: 8px;
  padding-bottom: 8px;
}
