@use 'styles/variables' as *;

.paymentPortalLink {
  margin-left: 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.paymentPortalText {
  font-style: normal;
  font-weight: $seminormalFontWeight;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white100Base;
  border-bottom: 1px solid $white100Base;
}

.paymentPortalIcon {
  stroke: $white100Base;
  margin-right: 2px;
}
