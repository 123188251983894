@use 'styles/variables' as *;

.optionContainer {
  display: flex;
  align-items: center;
  background: $surfacesLight;
  height: 44px;
  margin: 0 0 4px 4px;
  padding: 12px 16px;
}

.optionTitle {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: $primary100Base;
  margin-right: 6px;
}

.closeButton {
  max-height: 20px;
  max-width: 20px;
}

.disabled {
  background-color: $surfacesPrimary;

  & .optionTitle {
    color: $subtextColor;
  }

  & .closeButton {
    stroke: $subtextColor;
  }
}

.prefixElement {
  margin-right: 7px;
}

.labelContainer {
  margin: 0 0 4px 4px;
}
