.userAvatarCell {
  padding: 8px 10px 14px;
  flex-direction: column;
  align-items: flex-start;
}

.userAvatarContainer {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
}

.resultCell {
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.statusTag {
  margin-bottom: 20px;
}

.overflowedText {
  width: 100%;
}

.userAvatar {
  margin-right: 8px;
}
