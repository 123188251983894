@use 'styles/variables' as *;
@use 'styles/mixins' as *;

.header {
  display: flex;
  padding-bottom: 20px;
  color: $primary100Base;
  font-size: 22px;
  font-weight: $boldFontWeight;
  line-height: 28px;
}

.valueCell {
  @include p2Desktop;

  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: flex-start;
}

.topLine {
  @include descriptionText;

  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.action {
  display: flex;
  align-items: center;

  > svg {
    stroke: $defaultLinkColor;
    margin-right: 4px;
  }

  &:hover {
    > svg {
      stroke: $defaultLinkHoverColor;
    }
  }
}

.pricing {
  margin-left: 12px;
}

.nameCell {
  @include p2Desktop;

  color: $subtextColor;
  align-items: flex-start;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-style: normal;
  font-weight: $normalFontWeight;
}
