@use 'styles/variables' as *;

.addBtnContainer {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-top: 24px;
  width: 100%;
}

.addButton {
  display: flex;
  justify-content: center;
  padding: 0 4px 0 0;

  > svg {
    margin-right: 2px;
  }
}

.dashedLine {
  border-top: 1px dashed $primary10;
  flex-grow: 1;
  flex-shrink: 1;
}
