@use 'styles/variables' as *;

$shadowLeft: -10px 0px 20px $electricBlue110;
$shadowRight: 10px 0px 20px $electricBlue110;

.container {
  position: relative;
  background-color: transparent;
}

.loaderContainer {
  width: 154px;
  height: 115px;
  overflow: hidden;
  position: absolute;
  left: 20px;
  top: 14.5px;
}

.loader {
  position: absolute;
  width: 4px;
  height: 115px;
  top: 0;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  animation-name: move;
  background-color: $electricBlue110;
}

@keyframes move {
  0% {
    left: 0px;
    box-shadow: none;
  }

  15% {
    box-shadow: $shadowLeft;
  }

  35% {
    box-shadow: $shadowLeft;
  }

  50% {
    left: 150px;
    box-shadow: none;
  }

  75% {
    box-shadow: $shadowRight;
  }

  85% {
    box-shadow: $shadowRight;
  }

  100% {
    left: 0px;
    box-shadow: none;
  }
}

.text {
  color: $electricBlue110;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 2px;
  position: absolute;
  bottom: -3px;
  text-transform: uppercase;

  animation: pulsing 1.5s infinite ease;

  &::before {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $electricBlue110;
    left: -10px;
    top: 7px;
  }
}

.analyzingText {
  left: 48px;
}

.loadingText {
  left: 58px;
}

@keyframes pulsing {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  45% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
