@use "styles/variables" as *;
@use "styles/break-points" as *;

.selectInputWrapper {
  width: 100%;
}

.inputContainer {
  width: 100%;
  margin-top: 24px;
  &__label {
    position: relative;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $boldFontWeight;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    color: $headerTextColor;
  }

  &__error {
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $defaultErrorColor;
    margin-top: 4px;
  }
}

.inputContainerWithDataType {
  @extend .inputContainer;
  width: 100%;
}

.inputError {
  border: 1px solid $defaultErrorColor;
}

.select {
  position: relative;
  outline: none;
  border-radius: 4px;
}

.selectControl {
  margin-top: 8px;
  height: 52px;
  border: 1px solid $primary10;
  border-radius: 4px;
  padding: 16px 20px;
  cursor: pointer;
  width: 100%;
  background-color: $white100Base;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border: 1px solid $primary20;
  }

  .clearIcon {
    display: none;
  }

  & input {
    width: 100%;
    border: 0;
    font-family: $fontFamilyMonsterrat;
    font-style: normal;
    font-weight: $normalFontWeight;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    color: $headerTextColor;
    outline: none;
    cursor: pointer;

    &::placeholder {
      color: $placeholderTextColor;
    }
  }

  & .inputValueContainer {
    display: flex;
    width: 100%;
    margin-right: 10px;
  }

  & .inputLabelValueContainer {
    @extend .inputValueContainer;
    margin-right: 0;
  }

  & .emptyInput {
    width: 0;
    min-height: 0;
    height: 0;
    padding: 0;
  }

  &.inputFocus {
    border: 1px solid $secondary40;

    .hasError {
      border: 1px solid $red100Base;
    }

    &:hover > input {
      cursor: text;
    }
  }

  & svg {
    min-width: 24px;
  }
}

.selectControlWithRightNeighbour {
  @extend .selectControl;
  border-radius: 4px 0 0 4px;
}

.selectControlWithLeftNeighbour {
  @extend .selectControl;
  border-radius: 0 4px 4px 0;
}

.selectControlWithNeighbours {
  @extend .selectControl;
  border-radius: 0;
}

.highlightSelectControl {
  @extend .selectControl;
  border: 1px solid $defaultErrorColor;

  &:hover,
  &.inputFocus {
    border: 1px solid $defaultErrorColor;
  }
}

.inputContainerWithRightNeighbour {
  @extend .inputContainer;
  border-radius: 4px 0 0 4px;
  width: 100%;
}

.inputContainerWithLeftNeighbour {
  @extend .inputContainer;
  border-radius: 0 4px 4px 0;
  width: 100%;
}

.inputContainerWithNeighbours {
  @extend .inputContainer;
  border-radius: 0;
  width: 100%;
}

.readOnlySelectControl {
  @extend .selectControl;
  cursor: default;
  & input {
    cursor: default;
  }
}

.disabledSelectControl {
  @extend .readOnlySelectControl;
  background-color: $surfacesLight;
  &:hover {
    border: 1px solid $primary10;
  }
  & input {
    background-color: $surfacesLight;
  }
}

.optionsList {
  padding: 8px 0;
  position: absolute;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
  z-index: 999;

  &__hidden {
    display: none;
  }
}

.option {
  padding: 10px 16px 10px 20px;
  cursor: pointer;
  width: 100%;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  & input {
    display: none;
  }

  &TextContent {
    flex-grow: 1;
  }

  &CheckMark {
    height: 24px;
    width: 24px;
  }

  .name {
    color: $headerTextColor;
  }

  &Content {
    display: flex;
  }

  &:hover,
  &Active {
    background-color: $electricBlue10;

    .name {
      color: $electricBlue100;
    }
  }

  &Checked .name {
    color: $electricBlue100;
  }

  &DisabledTextType {
    cursor: default;

    .name {
      color: $primary40;
    }

    &.optionActive {
      background: $primary6;
    }
  }

  &DisabledBackgroundType {
    cursor: default;
    background-color: $surfacesLight;

    &:hover {
      background: $surfacesLight;
    }
  }
}

.branchName {
  display: flex;
  flex-direction: column;
}

.description {
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  display: flex;
  align-items: flex-end;
  font-feature-settings:
    "tnum" on,
    "lnum" on;
  color: $subtextColor;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.whiteLabel {
  color: $white100Base;
}

.defaultOption {
  @extend .option;
  color: $subtextColor;
}

div.inputLoader {
  width: auto;
}

.optionTextContent {
  margin-right: 5px;

  &.hasIcon {
    margin-left: 8px;
  }
}

.textLabel {
  visibility: hidden;
  font-family: $fontFamilyMonsterrat;
  position: absolute;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  padding-right: 3px;
}

.fullInputWidth {
  width: 90%;
}

.hiddenIconAfter {
  visibility: hidden;
}

.iconAfter {
  position: absolute;
  visibility: visible;
}

.baseInputIconContainer {
  position: relative;
  display: flex;
  align-self: stretch;
  align-items: center;
}

.inputIconContainer {
  @extend .baseInputIconContainer;

  & > svg {
    stroke: $headerTextColor;
  }
}

.customInputIconContainer {
  @extend .baseInputIconContainer;

  position: absolute;
  right: 16px;
}

.clearIcon {
  position: absolute;
  right: 100%;
  top: 0;
  stroke: $primary80;
  fill: $primary80;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
  background: $surfacesPrimary;
  box-shadow: -5px 0 5px 0 $surfacesPrimary;
}

.selectInputWithValue {
  &:hover .clearIcon,
  .showClearIcon {
    display: block;
  }

  &:hover .loader:not(.fixedLoader) {
    right: 80px;
  }
}

.errorNotification {
  color: $defaultErrorColor;
  margin-top: 4px;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
}

.optionContent {
  display: flex;
  align-items: center;
}

.loader {
  position: absolute;
  right: 50px;
}

.inputWithLabel {
  margin: 0 30px 0 8px;
}

.requiredSymbol {
  position: absolute;
  right: -8px;
  color: $red100Base;
}

.withSeparatorOption {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.labelWithIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.questionIcon {
  margin-left: 4px;
}

.desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile {
  display: none;
}

@include for-tablet-and-mobile-only {
  .mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    margin-top: 24px;
  }

  .selectInput {
    border-radius: 4px;
    border: 1px solid $primary10;
    background: $white100Base;
    padding: 16px 14px 16px 19px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $primary100Base;
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    font-family: $fontFamilyMonsterrat;
  }

  .desktop {
    display: none;
  }

  .arrowDownImage, .closeImage {
    position: absolute;
    top: 38px;
    right: 0;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
  }

  .arrowDownImage {
    right: 14px;
  }

  .closeImage {
    right: 42px;
  }

  .noValue {
    color: $primary40;
  }

  .mobileOption {
    color: $primary100Base;
  }
}
