@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.icon {
  fill: $white100Base;
  margin-right: 4px;
}

.container {
  background-color: $red100Base;
  height: 32px;
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  padding-left: 55px;
  z-index: 999;
}

.messageContainer {
  display: flex;
  align-items: center;
}

.message {
  font-style: normal;
  font-weight: $seminormalFontWeight;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white100Base;
  display: flex;
  align-items: center;
}

@include for-tablet-and-mobile-only {
  .container {
    padding: 16px;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .message {
    text-align: center;
  }

  .messageContainer {
    margin-bottom: 8px;
  }
}
