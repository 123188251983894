@use 'styles/variables' as *;

.tooltipContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkBtn {
  > p {
    color: $headerTextColor;
    border-bottom-color: $headerTextColor;
  }

  > svg {
    stroke: $headerTextColor;
  }
}
