@use 'styles/variables' as *;
@use 'styles/mixins' as *;

.popUpItem {
  @include buttonReset;
  padding: 12px 20px;
  transition: 0.25s all ease-in-out;
  width: 100%;
  font-family: $fontFamilyMonsterrat;
  font-weight: $normalFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  outline: 0;
  color: $defaultTextColor;

  &:not(.disabled):hover {
    background-color: $electricBlue10;
    color: $electricBlue100;
  }

  &.dangerButton:not(.disabled) {
    color: $red100Base;

    &:not(.disabled):hover {
      background-color: $red10;
      color: $red100Base;
    }
  }

  &.selected {
    color: $electricBlue100;
    display: flex;
    align-items: center;
  }
}

.disabled {
  background-color: $surfacesLight;
  color: $placeholderTextColor;
  pointer-events: none;
}

.checkIcon {
  margin: 0 8px 0 auto;
}
