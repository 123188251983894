@use 'styles/variables' as *;

.notification {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: $headerTextColor;
  box-shadow: 0px 8px 24px rgba(15, 35, 66, 0.2);
  min-height: 60px;
  padding-left: 24px;
  padding-right: 12px;
  align-items: center;

  &_success {
    background: $surfacesPrimary;
    border-left: 4px solid $green100Base;
  }

  &_info {
    background: $surfacesPrimary;
    border-left: 4px solid $surfacesSidebar;
  }

  &_warning {
    border-left: 4px solid $lightOrange100Base;
    background: $lightOrange10;
  }

  &_error {
    border-left: 4px solid $red100Base;
    color: $defaultErrorColor;
    background: $red10;
  }

  &__message {
    padding: 20px 0 20px 8px;
    font-size: $pFontSize;
    line-height: $pLineHeight;
    font-style: normal;
    font-weight: $normalFontWeight;
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: auto;
  }

  &__closeIcon {
    cursor: pointer;
    margin-left: 12px;
  }

  .icon {
    min-width: 24px;
    margin-right: 8px;
  }
}
