@use "styles/variables" as *;
@use '../../../../Auth/Variables.module.scss' as *;

.otherDocumentTypesContainer {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $typo60;
  margin-top: 8px;
}

.otherDocumentType {
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: $electricBlue100Base;
}
