@use 'styles/variables' as *;
@use 'styles/mixins' as *;

.linkButton {
  @include buttonReset;
  color: $defaultLinkColor;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  font-style: normal;
  font-weight: $normalFontWeight;
  font-family: $fontFamilyMonsterrat;
  cursor: pointer;

  &:hover {
    color: $defaultLinkHoverColor;
  }

  &.disabled {
    color: $primary40;
    cursor: default;
  }
}
