@use 'styles/variables' as *;

.icon {
  fill: $white100Base;
  margin-right: 4px;
}

.container {
  background-color: $red100Base;
  height: 32px;
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  padding-left: 55px;
  z-index: 999;
}

.message {
  font-style: normal;
  font-weight: $seminormalFontWeight;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $white100Base;
  display: flex;
  align-items: center;
}
