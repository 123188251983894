@use "styles/variables" as *;

$fileInfoBackground: #111e40;

.container {
  background-color: $electricBlue10;
  width: 100%;
  position: relative;
  overflow: overlay;
  min-height: 100px;

  &.disabled {
    overflow: hidden;
  }
}

.loading {
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $electricBlue10;
}

.loadingIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.closeIcon {
  position: absolute;
  top: 14px;
  right: 14px;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;

  > path {
    fill: $primary80;
  }
}

.controls {
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.fileInfo {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: $white100Base;
  background: linear-gradient(180deg,rgba(17,30,64,0), rgba($fileInfoBackground, 0.5));
}

.previewContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: $white100Base;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    pointer-events: none;
  }
}

.fullScreen {
  $headerHeight: 64px;

  .header {
    height: $headerHeight;
  }

  .previewContainer {
    height: calc(100vh - $headerHeight);
    margin-top: $headerHeight;
  }

  .controls {
    position: fixed;
  }
}
