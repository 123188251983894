@use 'styles/variables' as *;

.header {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 36px 22px 16px;
  border-bottom: 1px solid $primary10;
  background-color: $white100Base;
  z-index: 3;
}

.fileName {
  font-style: normal;
  font-weight: $boldFontWeight;
  font-size: $pFontSize;
  line-height: $pLineHeight;
  color: $headerTextColor;
  width: 100%;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.progressBar {
  width: 100%;
  background-color: $secondary3;
  border-radius: 2px;

  & div {
    background-color: $secondary100Base;
  }
}
