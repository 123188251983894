@use 'styles/variables' as *;
@use 'styles/break-points' as *;

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 22px 55px;
  font-family: $fontFamilyMonsterrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $primary40;

  @include for-tablet-and-mobile-only {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 19px;
    padding: 19px 0 20px 0;
  }
}

.separator {
  border-top: 1px solid $borderAndSeparatorsColor;
}

.links {
  display: flex;
  align-items: center;
  gap: 36px;

  @include for-tablet-and-mobile-only {
    gap: 20px;
  }
}

.link {
  color: $primary60;
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: $primary120;
  }
}

.statusIcon {
  width: 8px;
  height: 8px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: $green100Base;
}
