@import 'styles/variables';
@import '~reset-css/sass/reset';

html {
  height: 100%;
  font-family: $fontFamilyMonsterrat;
  box-sizing: border-box;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, .25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .1);
  border-radius: 0;
}

input {
  &[disabled] {
    -webkit-text-fill-color: $primary100Base;
    opacity: 1
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Regular'), url('./static/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Medium'), url('./static/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-SemiBold'), url('./static/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat-Bold'), url('./static/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
