@use 'styles/variables' as *;

$transitionDuration: 0.25s;

.tableRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $borderAndSeparatorsColor;
  transition: background-color $transitionDuration ease-in-out;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  font-weight: $normalFontWeight;
  color: $defaultTextColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-feature-settings: 'tnum' on, 'lnum' on;
  min-height: 43px;
}

.tableRowHover {
  @extend .tableRow;
  cursor: pointer;

  &:hover {
    background-color: $secondary6;
    color: $defaultTextColor;
  }
}

.selected {
  border: 1px solid $secondary100Base;
  background: $secondary10;
  height: 43px;
}

.alertIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.disabledTableRow {
  @extend .tableRow;
  background-color: $primary2;
  cursor: default;

  &:hover {
    background-color: $primary2;
  }

  & > * {
    opacity: 0.5;

    &:last-child {
      opacity: 1;
    }
  }
}

.noBottomBorder {
  border-bottom: none;
}

.noTopBorder {
  border-top: none;
}

.hasTopBorder {
  border-top: 1px solid $borderAndSeparatorsColor;
}
