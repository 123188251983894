@use "styles/variables" as *;
@use "styles/break-points" as *;

.rowContainer {
  position: relative;
  width: 100%;

  .removeButton {
    color: $red100Base;
    position: absolute;
    right: 0;
    top: 24px;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;

    &:hover {
      color: $red120;
    }
  }

  .disabled {
    color: $primary40;

    &:hover {
      color: $primary40;
      cursor: default;
    }
  }

  .errorMessage {
    color: $red100Base;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }

  .inputsContainer {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    width: 100%;

    .textInputContainer {
      width: 63%;
    }

    .selectContainer {
      width: 37%;
    }
  }
}

@include for-tablet-and-mobile-only {
  .selectContainer {
    select {
      max-height: 52px;
      border-radius: 0 4px 4px 0;
    }

    & > svg {
      top: 22px
    }
  }

  .clearIcon {
    display: none;
  }
}
