@use 'styles/variables' as *;

.documentTypeAutoCompletion {
  margin-bottom: 24px;
}

.applyFiltersButton {
  width: 100%;
  margin-top: 36px;
}

.statusMultiselectContainer {
  padding-top: 0;
}

:export {
  errorColor: $red100Base;
  successColor: $green100Base;
}
