@use 'components/Button/ButtonVariables' as *;

.button {
  padding: 16px 20px 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.primary {
    > svg {
      stroke: $primaryBtnTextColor;
      margin-right: 6px;
    }

    &:hover {
      > svg {
        stroke: $primaryBtnTextColorHover;
      }
    }
  }

  &.secondary {
    > svg {
      stroke: $secondaryBtnTextColor;
      margin-right: 6px;
    }

    &:hover {
      > svg {
        stroke: $secondaryBtnTextColorHover;
      }
    }
  }

  > svg {
    margin-right: 6px;
  }
}
